import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";
import theme from "./modules/theme";
import coins from "./modules/coins";
import platform from "./modules/platform";
import auth from "./modules/auth";
import account from "./modules/account";
import assets from "./modules/assets";
import coldWallet from "./modules/coldWallet";
import exchange from "./modules/exchange";
import finance from "./modules/finance";
Vue.use(Vuex);

export default new Vuex.Store({
  strict: true,
  plugins: [
    createPersistedState({
      paths: ["theme", "auth", "coldWallet", "assets"],
    }),
  ],
  modules: {
    theme,
    coins,
    platform,
    auth,
    account,
    assets,
    coldWallet,
    exchange,
    finance,
  },
  state: {},
  getters: {},
  mutations: {},
  actions: {},
});
