export default {
  message: {
    routeName: {
      wallets: "Portefeuilles",
      exchange: "Échange",
      swap: "Échanger",
      finance: "Finance",
      lending: "Prêt",
      "crypto Lend": "Staking",
      "cold Wallet": "Portefeuille froid",
      "account Settings": "Paramètres du compte",
      "account Security": "Sécurité du compte",
      "notification Settings": "Paramètres de notification",
    },
    login: {
      title: "Connexion au compte",
      email: "Entrez votre adresse e-mail",
      password: "Entrez votre mot de passe",
      validation_message: [
        "Assurez-vous de fournir une adresse e-mail valide",
        "Le mot de passe doit comporter au moins 8 caractères",
      ],
      forgot_password: "Mot de passe oublié?",
      no_account: "Vous n'avez pas de compte?",
      create_new: "Créer un nouveau",
      login_button: "Connexion",
    },
    register: {
      title: "Créer un compte",
      description: "Créez votre compte pour commencer",
      email_placeholder: "Adresse e-mail",
      password_placeholder: "Créez un mot de passe fort",
      email_error_message: "Veuillez entrer une adresse e-mail valide",
      password_error_message:
        "Le mot de passe doit comporter au moins 8 caractères",
      agree_marketing_label:
        "J'accepte de partager des données à des fins marketing.",
      email_updates_label:
        "J'accepte de recevoir des mises à jour par e-mail de NYX Exchange",
      submit_button_text: "Suivant",
      submit_button_loading_text: "Chargement...",
      have_account_message: "Vous avez déjà un compte?",
      login_link_text: "Connexion",
      prompt:
        "Merci de vous être inscrit sur NYX Exchange. Pour garantir la sauvegarde de votre compte et le bon fonctionnement de votre connexion, veuillez compléter l'ensemble du processus, y compris le téléchargement de votre pièce d'identité. Ne pas le faire pourrait entraîner la non-sauvegarde de votre compte et le dysfonctionnement de votre connexion.",
      otpMessage:
        "Vous disposerez de 60 secondes pour insérer votre OTP. Si vous ne le faites pas dans le délai imparti, le code expirera et vous devrez le renvoyer.",
    },
    emailVerification: {
      title: "Vérification par e-mail",
      description:
        "Veuillez saisir le code de vérification à 6 chiffres qui a été envoyé à {email}. Le code est valide pendant 30 minutes.",
      enterVerificationCode: "Entrer le code de vérification",
      resendCodeIn: "Renvoyer le code dans",
      resendCode: "Renvoyer le code",
      nextButton: "Suivant",
    },
    enterPhoneNumber: {
      title: "Entrer le numéro de téléphone",
      description:
        "Fournissez votre numéro de téléphone pour une vérification supplémentaire",
      placeholder: "Numéro de téléphone",
      nextButton: "Suivant",
    },
    phoneVerification: {
      title: "Vérification par téléphone",
      description:
        "Veuillez saisir le code de vérification à 6 chiffres qui a été envoyé à {phone}. Le code est valide pendant 30 minutes.",
      enterVerificationCode: "Entrer le code de vérification",
      resendCodeIn: "Renvoyer le code dans",
      resendCode: "Renvoyer le code",
      nextButton: "Suivant",
    },
    registrationSteps: {
      create_account: "Créer un compte",
      verify_email: "Vérifier l'e-mail",
      phone_number: "Numéro de téléphone",
      verify_phone: "Vérifier le téléphone",
    },
    forgotPassword: {
      title: "Mot de passe oublié",
      description: "Entrez l'e-mail pour réinitialiser le mot de passe",
      placeholder: "Adresse e-mail",
      validationMessage: "Veuillez entrer une adresse e-mail valide",
      goBack: "Retour",
      nextButton: "Suivant",
    },
    resetOption: {
      title: "Mot de passe oublié",
      description: "Sélectionnez la méthode de récupération ci-dessous.",
      sendToMobile: {
        title: "Envoyer sur mobile",
        description:
          "Envoyer un code unique sur votre numéro de téléphone mobile.",
      },
      sendToEmail: {
        title: "Envoyer par e-mail",
        description: "Envoyer un code unique à votre adresse e-mail.",
      },
      rememberPassword: "Se souvenir du mot de passe?",
      loginLink: "Connexion",
    },
    resetPhoneOptions: {
      title: "Mot de passe oublié",
      description:
        "Entrez le numéro de téléphone pour réinitialiser le mot de passe",
      placeholder: "Numéro de téléphone",
      goBack: "Retour",
      nextButton: "Suivant",
      rememberPassword: "Se souvenir du mot de passe?",
      loginLink: "Connexion",
    },
    resetPassword: {
      title: "Réinitialisation du mot de passe",
      description: "Choisissez un nouveau mot de passe pour votre compte",
      placeholder: "Créer un mot de passe fort",
      repeatPasswordPlaceholder: "Répéter le mot de passe",
      passwordErrorMessage:
        "Le mot de passe doit comporter au moins 8 caractères",
      passwordsDoNotMatchError: "Les mots de passe ne correspondent pas",
      resetButton: "Réinitialiser",
    },
    otpVerification: {
      title: "Vérification par OTP",
      description:
        "Veuillez saisir le code de vérification à 6 chiffres qui a été envoyé à",
      resendCodeIn: "Renvoyer le code dans",
      resendCode: "Renvoyer le code",
      enterVerificationCode: "Entrer le code de vérification",
      nextButton: "Suivant",
    },
    dashboardMenu: {
      wallets: "Portefeuilles",
      exchange: "Échange",
      swap: "Échanger",
      finance: "Finance",
      cold_wallet: "Portefeuille froid",
    },
    lending: {
      p2p: {
        title: "Prêt P2P",
        description:
          "Vous recherchez un prêt rapide ou souhaitez gagner des intérêts sur votre crypto-monnaie? Ne cherchez plus, car c'est l'endroit idéal!",
      },
      stake: {
        title: "Mise en jeu de crypto",
        description:
          "Misez une partie de votre crypto et gagnez des récompenses allant jusqu'à 14,5% l'an, spécialement pour les stablecoins.",
      },
    },
    cold_wallet: {
      understand: "Comprendre le portefeuille froid",
      regular: {
        title: "Portefeuille crypto régulier",
        description:
          "Effectuez toutes les transactions telles que le trading, l'échange instantané, l'envoi et la réception avec votre portefeuille régulier. Sécurisez vos actifs contre la fraude / les pirates informatiques avec le portefeuille froid.",
      },
      cold: {
        title: "Portefeuille froid",
        description:
          "Le stockage à froid peut être effectué via des portefeuilles logiciels hors ligne, où un processus d'authentification double est effectué. La clé publique reste sur la plateforme en ligne tandis qu'un environnement isolé génère les clés privées pour une sécurité optimale.",
      },
      safety: {
        title: "Sécurité supplémentaire pour vos actifs",
        description:
          "La crypto-monnaie est la plus vulnérable lorsqu'elle est stockée en ligne, ce qui fait du stockage à froid l'option idéale pour quiconque souhaite préserver de manière sécurisée de grandes quantités de crypto-actifs à long terme - des Hodlers inébranlables (investisseurs qui achètent et conservent leurs avoirs quel que soit le prix du marché) aux établissements qui ont des millions de dollars investis.",
      },
    },
    darkpoolSumarry: [
      "Seules les commandes à cours limité sont prises en charge pour Dark Pool.",
      "La taille minimale de commande pour les paires BTC équivaut à environ 100 000 USD.",
      "La taille minimale de commande pour les paires ETH équivaut à environ 50 000 USD.",
    ],
    generic: {
      wallets: "Portefeuilles",
      search: "Rechercher",
      pool: "Piscine",
      send: "Envoyer",
      receive: "Recevoir",
      swap: "Échanger",
      description: "Description",
      transactions: "Transactions",
      history: "Historique",
      high: "Haut",
      low: "Bas",
      selected: "Sélectionné",
      pair: "Paire",
      last_price: "Dernier Prix",
      volume: "Volume",
      activity: "Activité",
      balance: "Solde",
      market_order: "Ordre du Marché",
      limit_order: "Ordre à Limite",
      buy: "Acheter",
      sell: "Vendre",
      amount: "Montant",
      price: "Prix",
      set_alert: "Définir une Alerte",
      select: "Sélectionner",
      exchange: "Échange",
      from: "De",
      to: "à",
      total: "Total",
      active: "actif",
      you: "vous",
      your: "votre",
      have: "avoir",
      lend: "prêter",
      loan: "prêt",
      repay: "rembourser",
      lending: "prêt",
      closed: "fermé",
      risk: "risque",
      calculate: "calculer",
      rewards: "récompenses",
      staking: "staking",
      yield: "rendement",
      no: "non",
      stake: "part",
      previous: "précédent",
      add: "Ajouter",
      account: "Compte",
      documents: "Document",
      unique: "Identifiant Utilisateur Unique",
      language: "Langue",
      withdrawal: "Retrait",
      deposit: "Dépôt",
      sending: "Vous envoyez",
      authenticator: "Code Google Authenticator",
      pin: "Code PIN",
      enter: "Entrer",
      optional: "Optionnel",
      network: "Réseau",
      fee: "Frais",
      crypto: "Crypto",
      view_on_blockchain: "Voir le lien sur la Blockchain",
      change: "Changer",
      portfolio: "Portefeuille",
      age: "Âge",
      best: "Meilleur",
      asset: "Actif",
      highest: "Plus Haut",
      date: "Date",
      time: "Heure",
      transaction_type: "Type de Transaction",
      current_price: "Prix Actuel",
      months: "Mois",
      year: "Année",
      current: "Actuel",
      action: "Action",
      no_transactions: "Aucune transaction à afficher",
      filled: "Rempli",
      other: "Autre",
      trans_activity: "Activité de transaction",
      dark_pool: "Dark Pool",
      pending: "En attente",
      completed: "Terminé",
      error: "Une erreur s'est produite lors de la récupération des données",
      try_again: "Réessayer",
      swap_in_progress: "Échange en cours",
      swap_summary:
        "Votre transaction est en cours, elle prendra 30 minutes ou moins pour arriver sur votre solde. N'hésitez pas à utiliser l'application NYX Exchange pendant cette attente, et vous serez notifié une fois la transaction terminée.",
      borrow: "Emprunter",
      interest: "Intérêt",
      rate: "Taux",
      duration: "Durée",
      repayment: "Remboursement",
      accept_lend:
        "En acceptant cette demande, j'accepte les conditions de paiement du prêt. NYX Exchange n'est pas responsable de cette transaction de prêt. Ne prêtez pas à ceux en qui vous n'avez pas confiance.",
      accept: "Accepter",
      delete: "Supprimer",
      return_in: "Retour dans",
      debt: "Dette",
      liquidation: "liquidation",
      export: "exporter",
      back: "retour",
      paid: "payé",
      post: "publier",
      request: "demander",
      agree_post_loan:
        "En publiant cette demande, j'accepte les conditions de paiement du prêt",
      earn: "gagner",
      borrower: "emprunteur",
      plan: "plan",
      on: "sur",
      stable_coins: "Stable Coins",
      new: "nouveau",
      up: "en hausse",
      token: "jeton",
      term: "terme",
      per: "par",
      weekly: "hebdomadaire",
      for: "pour",
      withdraw: "Retirer",
      welcome: "Bienvenue",
      continue: "Continuer",
      dark_pool: "Piscine sombre",
      logout: "Déconnexion",
      security: "Sécurité",
      notifications: "Notifications",
      id_card: "Carte d'identité",
      proof_of_residence: "Justificatif de domicile",
      general: "Général",
      two_fa: "Paramètres 2FA",
      password: "Mot de passe",
      create: "Créer",
      repeat: "Répéter",
      same_password:
        "Le nouveau mot de passe ne peut pas être le même que l'ancien mot de passe",
      no_match_password: "Les mots de passe ne correspondent pas",
      coming_soon: "Bientôt disponible",
      verified: "Vérifié",
    },
    location: {
      popup:
        "Merci de votre intérêt pour l'échange NYX Exchange. Malheureusement, nous ne pouvons actuellement pas fournir de service dans votre pays. Nous vous encourageons à revenir régulièrement alors que nous étendons notre portée et obtenons de nouvelles licences pour servir davantage de pays.",
    },
    two_fa: {
      recommended: "Recommandé",
      google: "Application Google Authenticator",
      activate: "Activer",
      deactivate: "Désactiver",
      summary:
        "Protégez votre compte en demandant une couche de sécurité supplémentaire pour vous connecter.",
      scan_message:
        "Ouvrez votre application Google Auth et scannez le code ci-dessous",
      withdraw: {
        title: "Authentification de retrait",
        description:
          "Protégez vos fonds en utilisant une confirmation 2FA chaque fois que vous retirez des fonds ou générez une nouvelle adresse de dépôt.",
      },
    },
    ai_notifications: {
      title: "Notifications A.I",
      description:
        "Envoyez des notifications push sur les prix des cryptos sur le marché en fonction de vos intérêts avec un algorithme intelligent.",
    },
    admin_emails: {
      title: "Emails administratifs",
      description:
        "Mises à jour non critiques susceptibles d'affecter votre compte NYX Exchange (par exemple, nouvelles fonctionnalités ou services, mises à jour de politique, etc.)",
    },
    newsletter_emails: {
      title: "Emails de newsletter",
      description:
        "Informations utiles qui pourraient vous intéresser (par exemple, articles de blog, analyses et faits intéressants sur l'industrie du Bitcoin, offres spéciales, etc.)",
    },
    common: {
      on: "ON",
      off: "OFF",
    },
    kyc: {
      kyc_complete_action:
        "Vos documents KYC apparaîtront ici une fois approuvés.",
      kyc_in_progress:
        "Vérification KYC en cours. Vous serez informé lorsque votre compte aura été vérifié.",
      kyc_not_started:
        "Vérifiez votre compte ici. Une webcam est nécessaire, vous pouvez le faire sur votre téléphone ou activez la webcam sur votre ordinateur.",
      header: {
        buttonText: "Cliquez ici",
        message:
          "pour compléter la vérification KYC et activer pleinement votre compte",
      },
      popup:
        "Merci de rejoindre NYX Exchange ! Les pages suivantes sont dédiées à la vérification de votre identité, vous permettant d'utiliser pleinement la plateforme d'échange et de trader en toute liberté. Nous avons collaboré avec ShuftiPro, un fournisseur KYC tiers de confiance, pour stocker en toute sécurité toutes les données personnelles sur leurs serveurs. Si vous souhaitez annuler votre compte à tout moment, veuillez contacter NYX Exchange à support@nyxex.com, et nous demanderons rapidement à ShuftiPro de supprimer vos informations de leurs serveurs. De plus, nous veillerons à ce que toutes vos données personnelles, sauf celles légalement nécessaires à la conservation, soient complètement effacées de nos serveurs. Encore une fois, merci d'avoir choisi NYX Exchange. Nous sommes impatients de vous servir !",
      identityVerification: {
        title: "Vérifiez votre identité",
        fullName: "Nom complet",
        nameErrorMessage: "Le nom doit comporter au moins 5 caractères",
        dateOfBirth: "Date de naissance",
        address: "Adresse",
        addressErrorMessage: "L'adresse doit comporter au moins 5 caractères",
        city: "Ville",
        cityErrorMessage: "La ville doit comporter au moins 3 caractères",
        country: "Pays",
        currency: "Devise",
        agreePrivacyPolicy:
          "J'accepte la politique de confidentialité de NYX Exchange.",
        privacyPolicyLink: "Politique de confidentialité",
        submitButton: "Suivant",
        isLoadingText: "Chargement...",
      },
      proofOfResidence: {
        title: "Justificatif de domicile",
        tipsTitle: "Conseils :",
        tips: [
          "Conseil 1 : Fournissez un document clair et lisible",
          "Conseil 2 : Assurez-vous que le document indique votre adresse résidentielle actuelle",
          "Conseil 3 : Évitez tout éblouissement ou reflet sur le document",
        ],
        buttonText: "Soumettre",
      },
      takePhotoOfID: {
        title: "Prenez une photo de votre pièce d'identité",
        tipsTitle: "Document accepté :",
        tips: [
          "Conseil 1 : Assurez-vous que le document est clair et lisible",
          "Conseil 2 : Capturez l'ensemble de la pièce d'identité dans le cadre",
          "Conseil 3 : Évitez les reflets ou les éblouissements sur le document",
        ],
        buttonText: "Soumettre",
      },
      takePhotoOfYou: {
        title: "Prenez une photo de vous",
        tipsTitle: "Conseils :",
        tips: [
          "Conseil 1 : Trouvez un endroit bien éclairé pour une meilleure qualité de photo.",
          "Conseil 2 : Retirez tous les accessoires qui pourraient obstruer votre visage.",
        ],
        buttonText: "Soumettre",
      },
      selfieWithYourID: {
        title: "Selfie avec votre pièce d'identité",
        tipsTitle: "Conseils :",
        tips: [
          "Conseil 1 : Assurez-vous que votre visage et votre pièce d'identité sont bien éclairés pour une photo nette.",
          "Conseil 2 : Retirez tous les accessoires qui pourraient obstruer votre visage ou votre pièce d'identité.",
        ],
        buttonText: "Soumettre",
      },
      waitingForApproval: {
        title: "En attente d'approbation",
        description:
          "Merci d'avoir choisi NYX Exchange pour vos besoins en crypto. Votre compte a été enregistré et vos documents ont été téléchargés. Vous pouvez maintenant vous connecter et consulter le tableau de bord d'échange, ainsi que déplacer des crypto-monnaies de et vers vos portefeuilles. Si vous souhaitez acheter ou vendre des crypto-monnaies, veuillez cliquer sur l'option \"Acheter\" ou \"Vendre\" dans l'onglet d'échange et compléter le processus KYC de base pour une approbation immédiate. Merci encore une fois, et n'hésitez pas à nous contacter à support@morizonx.com si vous avez besoin d'une assistance supplémentaire.",
        closeButton: "Fermer",
      },
    },
    wallet: {
      sendCaution:
        "Attention : Ne transférez que le jeton spécifié à cette adresse ; l'envoi de tout autre jeton pourrait entraîner une perte irréversible. Vérifiez et procédez avec prudence.",
    },
    exchange: {
      prompt: `
    <p>Merci de vous être inscrit sur NYX Exchange.</p>
    <p>Pour ceux d'entre vous qui ont réservé des NYX Exchange Shares, vous pouvez maintenant effectuer le paiement aujourd'hui.</p>
    <p>Le prix actuel est de £63 par Share, que vous devrez convertir en $USD en utilisant le convertisseur de devises <a href="https://xe.com" target="_blank">https://xe.com</a>.</p>
    <p>Le paiement doit être effectué en USDT à l'adresse de portefeuille USDT ERC-20 suivante:</p>
    <p><strong>0x8DD4F5034D97602aAef43c42bC22269FAF6f356B</strong></p>
    <p>Si vous avez de l'USDT TRC-20, vous devrez d'abord le convertir en USDT ERC-20 en utilisant un moteur d'échange tel que <a href="https://simpleswap.io" target="_blank">https://simpleswap.io</a>.</p>
    <p>Une fois converti, vous pourrez envoyer votre paiement à l'adresse de portefeuille NYX Exchange USDT ERC-20 suivante:</p>
    <p><strong>0x8DD4F5034D97602aAef43c42bC22269FAF6f356B</strong></p>
    <p>Veuillez insérer votre Prénom et Nom dans la section 'Note Personnelle' du 'Formulaire d'envoi' lors de votre paiement.</p>
    <p>Si vous avez besoin d'aide, veuillez envoyer un e-mail à <a href="mailto:shares@morizonbaank.com">shares@morizonbaank.com</a>.</p>
    <p>Merci pour votre soutien,</p>
    <p>L'équipe NYX Exchange</p>
  `,
    },
  },
};
