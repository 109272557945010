<template>
  <div class="sidebar left-sidebar" ref="sidebar">
    <div class="px-2">
      <div v-for="item in menuItems" :key="item.id" class="left-sidebar__item">
        <router-link :to="item.path" class="left-sidebar__item-link">
          <img :src="item.icon" class="left-sidebar__item-link-icon" alt="" />
          <div>{{ $t(`message.dashboardMenu.${item.id}`) }}</div>
        </router-link>
      </div>
    </div>
    <div class="px-2">
      <a
        href="javascript:void(0)"
        @click="showComingSoon"
        class="left-sidebar__item-link"
      >
        <img :src="NFTIcon" class="left-sidebar__item-link-icon" alt="" />
        <div>NFT</div>
      </a>
    </div>
    <div class="left-sidebar__divider"></div>
    <div class="px-2">
      <router-link to="/dashboard/cold-wallet" class="left-sidebar__item-link">
        <img
          :src="ColdWalletIcon"
          class="left-sidebar__item-link-icon"
          alt=""
        />
        <div>{{ $t(`message.dashboardMenu.cold_wallet`) }}</div>
      </router-link>
    </div>
    <div class="px-2">
      <div ref="notificationButton">
        <Notifications :top="distance" />
      </div>
      <router-link to="/dashboard/account" class="left-sidebar__item-action">
        <img :src="AccountIcon" class="left-sidebar__item-link-icon" alt="" />
      </router-link>
      <button class="left-sidebar__item-action" @click="logoutUser">
        <img :src="LogoutIcon" class="left-sidebar__item-link-icon" alt="" />
      </button>
    </div>
    <div class="px-2">
      <ThemeSwitch />
    </div>
  </div>
</template>

<script>
import WalletIcon from "@/assets/menuIcons/Wallets.svg";
import ExchangeIcon from "@/assets/menuIcons/Exchange.svg";
import SwapIcon from "@/assets/menuIcons/Swap.svg";
import FinanceIcon from "@/assets/menuIcons/Finance.svg";
import NFTIcon from "@/assets/menuIcons/NFT.svg";
import ColdWalletIcon from "@/assets/menuIcons/ColdWallet.png";
import AccountIcon from "@/assets/menuIcons/Account.svg";
import LogoutIcon from "@/assets/menuIcons/Logout.svg";
import ThemeSwitch from "./ThemeSwitch.vue";
import Notifications from "@/components/Dashboard/Notifications/Notifications";
export default {
  name: "LeftSidebar",
  components: {
    ThemeSwitch,
    Notifications,
  },
  data() {
    return {
      distance: 0,
      ColdWalletIcon,
      AccountIcon,
      LogoutIcon,
      NFTIcon,
      menuItems: [
        {
          id: "wallets",
          name: "Wallets",
          icon: WalletIcon,
          path: "/dashboard/wallets",
        },
        {
          id: "exchange",
          name: "Exchange",
          icon: ExchangeIcon,
          path: "/dashboard/exchange",
        },
        {
          id: "swap",
          name: "Swap",
          icon: SwapIcon,
          path: "/dashboard/swap",
        },
        {
          id: "finance",
          name: "Finance",
          icon: FinanceIcon,
          path: "/dashboard/finance",
        },
        // {
        //  id: "nft",
        //  name: "NFT",
        //  icon: NFTIcon,
        //  path: '/dashboard/nft'
        // },
      ],
      isNotificationVisible: false,
    };
  },
  mounted() {
    this.getDistance();
  },
  methods: {
    getDistance() {
      const sidebar = this.$refs.sidebar;
      const notificationButton = this.$refs.notificationButton;

      if (!sidebar || !notificationButton) {
        return 0;
      }
      this.$nextTick(() => {
        const sidebarTop = sidebar.getBoundingClientRect().top;
        const notificationButtonTop =
          notificationButton.getBoundingClientRect().top;
        this.distance = notificationButtonTop - sidebarTop;
      });
    },
    showComingSoon() {
      const message = this.$t("message.generic.coming_soon");
      this.$toast.info(message);
    },
    logoutUser() {
      this.$store.dispatch("auth/logout");
      this.$router.push("/auth/login");
    },
  },
};
</script>

<style></style>
