<template>
  <div class="nx-language-change">
    <select v-model="$i18n.locale">
      <option v-for="(lang, i) in langs" :key="`Lang${i}`" :value="lang.value">
        {{ lang.name }}
      </option>
    </select>
  </div>
</template>

<script>
export default {
  name: "LanguageChange",
  data() {
    return {
      langs: [
        { name: "English", value: "en" },
        { name: "French", value: "fr" },
        { name: "Spanish (Latin America)", value: "la_es" },
        { name: "Portuguese (Brazil)", value: "pt_br" },
      ],
    };
  },
};
</script>

<style>
.nx-language-change {
  position: fixed;
  bottom: 0;
  right: 0;
  z-index: 1000;
}
</style>
