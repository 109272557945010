<template>
  <div class="kyc-modal" v-if="isOpen">
    <div class="kyc-modal__content">
      <div
        class="kyc-modal__content-header d-flex align-items-center justify-content-center"
      >
        <h3 class="text__body text-white mb-0 text-is--medium">
          {{ $t(`message.generic.welcome`) }}
          {{ $t(`message.generic.to`) }} NYX Exchange
        </h3>
      </div>
      <div class="p-3 p-lg-5 text-center">
        <p class="text__body--small text-is--small text-is--dark">
          {{ $t(`message.location.popup`) }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "LocationPopup",
  data() {
    return {
      isOpen: false,
    };
  },
  methods: {
    openPopup() {
      this.isOpen = true;
    },
    closePopup() {
      this.isOpen = true;
    },
  },
};
</script>

<style scoped>
.kyc-modal {
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 999;
  top: 0;
  left: 0;
  overflow-y: auto;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  padding: 4rem 1rem;
  background: rgba(0, 0, 0, 0.7);
  box-shadow: 0 10px 20px -10px rgb(0 0 0 / 20%);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  transition: 0.2s ease-in-out;
}

.kyc-modal .kyc-modal__content {
  height: auto;
  width: 100%;
  max-width: 600px;
  background: var(--baseWhite);
  border-top-left-radius: 1rem;
  border-top-right-radius: 1rem;
}

.kyc-modal .kyc-modal__content .kyc-modal__content-header {
  min-height: 50px;
  border-top-left-radius: 1rem;
  border-top-right-radius: 1rem;
  background: var(--primaryPurple);
}
</style>
