<template>
  <div>
    <label class="nyx-toggle">
      <span class="label" :class="{ checked: AppTheme === 'dark_theme' }">
        <i
          :class="[
            AppTheme === 'dark_theme'
              ? 'bi bi-moon-stars-fill'
              : 'bi bi-brightness-high-fill',
          ]"
        ></i>
      </span>
      <input
        type="checkbox"
        @change="toggleThemeCheckbox"
        :checked="AppTheme === 'dark_theme'"
      />
      <span class="slider round"></span>
    </label>
  </div>
</template>

<script>
export default {
  name: "ThemeSwitch",
  data() {
    return {
      darkTheme: false,
    };
  },
  methods: {
    toggleThemeCheckbox() {
      this.$store.dispatch("theme/toggleTheme");
      this.$emit("themeSwitched");
    },
  },
};
</script>
