var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{ref:"dropdownMenu",staticClass:"position-relative"},[_c('div',{staticClass:"form-group mb-2 cursor--pointer",on:{"click":_vm.toggleDropdown}},[_c('div',{staticClass:"nx-input-group",class:{
        'nx-input-group--small': _vm.isSmall,
        'nx-input-group--small-radius': _vm.smallRadius,
      }},[(_vm.selectedOption)?_c('span',{staticClass:"text__body--small text-is--dark text-is--semibold me-1",class:{
          'text__body--smaller': _vm.isSmall,
        }},[_vm._v(_vm._s(_vm._f("shortenString")(_vm.selectedOption.value)))]):_c('div',{staticClass:"d-flex align-items-center me-1"},[_c('div',{staticClass:"text__body--small text-is--dark text-is--regular"},[_vm._v(" "+_vm._s(_vm.placeholder)+" ")])]),_c('div',{staticClass:"nx-input-group__icon text__body--smaller text-is--blend-grey"},[(_vm.dropdownOpen)?_c('i',{staticClass:"bi bi-caret-up-square"}):_c('i',{staticClass:"bi bi-caret-down-square"})])])]),_c('div',{staticClass:"nyx-dropdown-menu",class:{
      'nyx-dropdown-menu--closed': !_vm.dropdownOpen,
      'nyx-dropdown-menu--open': _vm.dropdownOpen,
      'nyx-dropdown-menu--absolute': _vm.isAbsolute,
    }},[(_vm.options.length > 5 && !_vm.hideSearch)?_c('GenericSearchbox',{attrs:{"placeholder":`${_vm.$t(`message.generic.search`)}`},model:{value:(_vm.searchTerm),callback:function ($$v) {_vm.searchTerm=$$v},expression:"searchTerm"}}):_vm._e(),_vm._l((_vm.computedOptions),function(option){return _c('div',{key:option.label,on:{"click":function($event){return _vm.chooseOption(option)}}},[_c('TextDropdownItem',{attrs:{"option":option,"isActive":_vm.selectedOption ? _vm.selectedOption.value == option.value : false}})],1)})],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }