// Brand Assets
export const BRAND = {
  FullWhiteLogo: require("@/assets/brand/full-logo__light.png"),
  FullDarkLogo: require("@/assets/brand/full-logo__dark.png"),
};
export const IMAGES = {
  AuthUSFlag: require("@/assets/auth/us-flag.png"),
};
export const COINS = {
  RORAG: require("@/assets/coins/rorag.svg"),
  RORAP: require("@/assets/coins/rorap.svg"),
  RXOGC: require("@/assets/coins/rxogc.svg"),
};
export const ICONS = {
  AuthEmail: require("@/assets/icons/AuthEmail.svg"),
  AuthPassword: require("@/assets/icons/AuthPassword.svg"),
  BoxArrowRight: require("@/assets/icons/BoxArrowRight.svg"),
  BoxArrowRightPurple: require("@/assets/icons/BoxArrowRightPurple.svg"),
  SearchIcon: require("@/assets/icons/SearchIcon.svg"),
  SearchSortIcon: require("@/assets/icons/SearchSortIcon.svg"),
  SearchSortIconReverse: require("@/assets/icons/SearchSortIconReverse.svg"),
  DarkpoolWhite: require("@/assets/icons/DarkpoolWhite.svg"),
  DarkpoolBlack: require("@/assets/icons/DarkpoolBlack.svg"),
};
