<template>
  <AuthCard>
    <div class="mb-2">
      <h1 class="text__title text-is--primary text-is--regular">
        {{ $t("message.emailVerification.title") }}
      </h1>
      <p
        class="text__body--small text-is--dark text-is--light"
        v-if="fields && fields.email"
      >
        {{ $t("message.register.otpMessage") }}
      </p>
    </div>
    <form @submit.prevent="verifyEmail">
      <div class="form-group mb-4">
        <div class="nx-input-group">
          <input
            type="password"
            v-model.trim="$v.emailOTP.$model"
            :placeholder="$t('message.emailVerification.enterVerificationCode')"
            autocomplete="new-password"
            inputmode="numeric"
            pattern="[0-9]*"
            v-numeric
          />
        </div>
      </div>
      <div
        class="mt-5 d-block d-md-flex align-items-center justify-content-between"
      >
        <div v-if="otpCounterActive">
          <div class="text__body text-is--dark d-flex align-items-center">
            {{ $t("message.emailVerification.resendCodeIn") }}
            <OtpCountdown
              ref="countdown"
              :minutes="0"
              :seconds="60"
              @countdownEnded="countdownEnded"
            />
          </div>
        </div>
        <div v-show="!otpCounterActive && !isLoading">
          <div class="text__body d-flex align-items-center">
            <a
              href="javascript:void(0)"
              class="text-is--primary text-decoration-none text-is--semibold"
              @click="resendOtp"
              >{{ $t("message.emailVerification.resendCode") }}</a
            >
          </div>
        </div>
        <div class="d-flex align-items-center">
          <button
            type="button"
            class="nx-button nx-button--rounded nx-button--primary text-capitalize"
            :disabled="isLoading"
            @click="previousStep"
          >
            {{ $t("message.generic.back") }}
          </button>
          <div class="mx-2"></div>
          <button
            class="nx-button nx-button--rounded nx-button--primary"
            type="submit"
            :disabled="isLoading || $v.$invalid"
          >
            <span>{{ $t("message.emailVerification.nextButton") }}</span>
            <img
              :src="AppAssets.ICONS.BoxArrowRight"
              class="nx-button__icon"
              alt=""
              v-if="!isLoading"
            />
            <InlineLoader v-if="isLoading" />
          </button>
        </div>
      </div>
    </form>
  </AuthCard>
</template>

<script>
import { AuthCard } from "@/components";
import { required, email, minLength } from "vuelidate/lib/validators";
export default {
  name: "EmailVerification",
  components: { AuthCard },
  data() {
    return {
      fields: null,
      emailOTP: "",
      isLoading: false,
      otpCounterActive: false,
    };
  },
  validations: {
    emailOTP: {
      required,
    },
  },
  methods: {
    async resendOtp() {
      try {
        await this.$store.dispatch("auth/sendEmailVerification", {
          email: this.fields.email?.toLowerCase(),
        });
        this.startOtpTimer();
      } catch (error) {
        console.log(error);
        if (error.response && error.response.data) {
          this.$toast.error(error.response.data[0].message);
        } else {
          this.$toast.error("An error occurred. Please try again");
        }
      }
    },
    previousStep() {
      this.$emit("previousStep");
      this.otpCounterActive = false;
      this.$refs.countdown.reset();
    },
    async startOtpTimer() {
      this.otpCounterActive = true;
      await this.$nextTick();
      this.$refs.countdown.start();
    },
    async verifyEmail() {
      this.isLoading = true;
      try {
        const data = {
          ...this.fields,
          emailOTP: this.emailOTP,
        };
        await this.$store.dispatch("auth/verifyEmail", {
          email: data.email?.toLowerCase(),
          token: data.emailOTP,
        });
        await this.$store.dispatch("auth/register", {
          password: this.fields.password,
          emailOTP: this.emailOTP,
          // phoneOTP: this.phoneOTP,
          shareData: this.fields.shareData,
          recieveUpdates: this.fields.recieveUpdates,
        });
        this.$router.push("/account/kyc");
      } catch (error) {
        console.log(error);
        if (error.response && error.response.data) {
          this.$toast.error(error.response.data[0].message);
        } else {
          this.$toast.error("An error occurred. Please try again");
        }
      } finally {
        this.isLoading = false;
      }
    },
    countdownEnded() {
      this.otpCounterActive = false;
    },
    async setFields(fields) {
      this.fields = {
        ...fields,
      };
      await this.$nextTick();
      this.startOtpTimer();
    },
  },
};
</script>

<style></style>
