<template>
 <div class="d-flex align-items-start flex-column">
  <div class="text__body--small text-is--dark text-is--semibold ">
   {{ wallet.name }} (<span class="text-uppercase">{{ wallet.symbol }}</span>)
  </div>
  <div class="text__body--smaller text-is--light text-is--dark">Balance: {{ wallet.balance }}</div>
 </div>
</template>

<script>
export default {
 name: "CryptoDropdownWithBalance",
 props: {
  activeWallet: Object
 },
 computed: {
  wallet() {
   return this.activeWallet;
  }
 }
}
</script>

<style></style>