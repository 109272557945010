<template>
  <AuthCard>
    <div class="mb-2">
      <h1 class="text__title text-is--primary text-is--regular">
        {{ $t("message.register.title") }}
      </h1>
      <p class="text__body--small text-is--dark text-is--light">
        {{ $t("message.register.prompt") }}
      </p>
    </div>
    <form @submit.prevent="createAccount">
      <div class="my-5"></div>
      <div class="form-group mb-4">
        <div class="nx-input-group">
          <input
            type="email"
            :placeholder="$t('message.register.email_placeholder')"
            autocomplete="new-password"
            v-model.trim="$v.email.$model"
          />
          <div class="nx-input-group__icon">
            <img :src="AppAssets.ICONS.AuthEmail" alt="" />
          </div>
        </div>
        <InlineError
          :message="$t('message.register.email_error_message')"
          v-if="email && $v.email.$invalid"
        />
      </div>
      <div class="form-group mb-4">
        <div class="nx-input-group">
          <input
            :type="show_password ? 'text' : 'password'"
            :placeholder="$t('message.register.password_placeholder')"
            autocomplete="new-password"
            v-model.trim="$v.password.$model"
          />
          <div
            class="nx-input-group__icon opacity-50 cursor--pointer text-is--dark"
            @click="show_password = !show_password"
          >
            <i :class="[show_password ? 'bi bi-eye-slash' : 'bi bi-eye']"></i>
          </div>
        </div>
        <InlineError
          :message="$t('message.register.password_error_message')"
          v-if="password && $v.password.$invalid"
        />
      </div>
      <div
        class="mt-5 d-block d-md-flex align-items-center justify-content-between"
      >
        <div class="pe-1">
          <div class="check-group my-3">
            <input type="checkbox" id="agree_marketing" v-model="shareData" />
            <label for="agree_marketing" class="text-is--dark text-is--light">
              {{ $t("message.register.agree_marketing_label") }}
            </label>
          </div>
          <div class="check-group my-3">
            <input
              type="checkbox"
              id="email_updates"
              v-model="recieveUpdates"
            />
            <label for="email_updates" class="text-is--dark text-is--light">
              {{ $t("message.register.email_updates_label") }}
            </label>
          </div>
        </div>
        <button
          class="nx-button nx-button--rounded nx-button--primary"
          type="submit"
          :disabled="isLoading || $v.$invalid"
        >
          <span>{{ $t("message.register.submit_button_text") }}</span>
          <img
            :src="AppAssets.ICONS.BoxArrowRight"
            class="nx-button__icon"
            alt=""
            v-if="!isLoading"
          />
          <InlineLoader v-if="isLoading" />
        </button>
      </div>
      <div
        class="text-center text__body--small text-is--regular text-is--dark mt-5"
      >
        {{ $t("message.register.have_account_message") }}
        <router-link
          to="/auth/login"
          class="text-is--primary text-is--semibold"
        >
          {{ $t("message.login.login_button") }}
        </router-link>
      </div>
    </form>
  </AuthCard>
</template>

<script>
import { AuthCard } from "@/components";
import { required, email, minLength } from "vuelidate/lib/validators";
export default {
  name: "BasicInfo",
  components: { AuthCard },
  data() {
    return {
      email: "",
      password: "",
      recieveUpdates: true,
      shareData: true,
      isLoading: false,
      show_password: false,
    };
  },
  validations: {
    email: {
      required,
      email,
    },
    password: {
      required,
      minLength: minLength(8),
    },
  },
  methods: {
    async createAccount() {
      this.isLoading = true;
      try {
        const data = {
          email: this.email?.toLowerCase(),
          password: this.password,
          recieveUpdates: this.recieveUpdates,
          shareData: this.shareData,
        };
        await this.$store.dispatch("auth/sendEmailVerification", {
          email: this.email?.toLowerCase(),
        });
        this.$emit("accountCreated", data);
      } catch (error) {
        console.log(error);
        if (error.response && error.response.data) {
          this.$toast.error(error.response.data[0].message);
        } else {
          this.$toast.error("An error occurred. Please try again");
        }
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>

<style></style>
