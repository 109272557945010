<template>
  <div ref="dropdownMenu" class="position-relative">
    <div class="form-group mb-2 cursor--pointer" @click="toggleDropdown">
      <div
        class="nx-input-group"
        :class="{
          'nx-input-group--small': isSmall,
          'nx-input-group--small-radius': smallRadius,
        }"
      >
        <span
          class="text__body--small text-is--dark text-is--semibold me-1"
          :class="{
            'text__body--smaller': isSmall,
          }"
          v-if="selectedOption"
          >{{ selectedOption.value | shortenString }}</span
        >
        <div class="d-flex align-items-center me-1" v-else>
          <div class="text__body--small text-is--dark text-is--regular">
            {{ placeholder }}
          </div>
        </div>
        <div
          class="nx-input-group__icon text__body--smaller text-is--blend-grey"
        >
          <i class="bi bi-caret-up-square" v-if="dropdownOpen"></i>
          <i class="bi bi-caret-down-square" v-else></i>
        </div>
      </div>
    </div>
    <div
      class="nyx-dropdown-menu"
      :class="{
        'nyx-dropdown-menu--closed': !dropdownOpen,
        'nyx-dropdown-menu--open': dropdownOpen,
        'nyx-dropdown-menu--absolute': isAbsolute,
      }"
    >
      <GenericSearchbox
        v-if="options.length > 5 && !hideSearch"
        :placeholder="`${$t(`message.generic.search`)}`"
        v-model="searchTerm"
      />
      <div
        v-for="option in computedOptions"
        :key="option.label"
        @click="chooseOption(option)"
      >
        <TextDropdownItem
          :option="option"
          :isActive="
            selectedOption ? selectedOption.value == option.value : false
          "
        />
      </div>
    </div>
  </div>
</template>

<script>
import TextDropdownItem from "@/components/Global/Dropdowns/TextDropdownItem";
export default {
  name: "TextDropdown",
  props: {
    options: {
      type: Array,
      required: true,
    },
    selected: {
      type: Object,
      default: null,
    },
    isSmall: Boolean,
    smallRadius: Boolean,
    isAbsolute: Boolean,
    hideSearch: Boolean,
    placeholder: {
      type: String,
      default: "Select Option",
    },
  },
  components: { TextDropdownItem },
  data() {
    return {
      selectedOption: this.selected,
      dropdownOpen: false,
      coinSelected: false,
      searchTerm: "",
    };
  },
  computed: {
    computedOptions() {
      let filtered = [...this.options];
      filtered.sort((a, b) => a.value.localeCompare(b.value));
      if (this.searchTerm) {
        filtered = filtered.filter((option) =>
          option.value.toLowerCase().includes(this.searchTerm.toLowerCase())
        );
      }
      if (this.selectedOption) {
        filtered = filtered.sort((a, b) => {
          if (a.value === this.selectedOption.value) {
            return -1;
          }
          if (b.value === this.selectedOption.value) {
            return 1;
          }
          return 0;
        });
      }
      return filtered;
    },
  },
  filters: {
    shortenString: function (value) {
      if (value.length > 12) {
        return value.slice(0, 12) + "...";
      } else {
        return value;
      }
    },
  },
  mounted() {
    document.addEventListener("click", this.handleOutsideClick);
  },
  beforeUnmount() {
    document.removeEventListener("click", this.handleOutsideClick);
  },
  methods: {
    handleOutsideClick(event) {
      const dropdownMenu = this.$refs.dropdownMenu;
      if (!dropdownMenu || !dropdownMenu.contains(event.target)) {
        this.dropdownOpen = false;
      }
    },
    toggleDropdown() {
      this.dropdownOpen = !this.dropdownOpen;
    },
    chooseOption(option) {
      this.selectedOption = option;
      this.dropdownOpen = false;
      this.searchTerm = "";
      this.$emit("selected", option);
    },
  },
};
</script>

<style></style>
