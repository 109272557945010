export const routes = [
  {
    path: "/",
    name: "home",
    redirect: "/auth/login",
  },
  {
    path: "/auth/login",
    name: "Login",
    component: () => import("@/views/Auth/Login.vue"),
  },
  {
    path: "/auth/forgot-password",
    name: "ForgotPassword",
    component: () => import("@/views/Auth/ForgotPassword.vue"),
  },
  {
    path: "/auth/register",
    name: "Register",
    component: () => import("@/views/Auth/Register.vue"),
  },
  {
    path: "/account/kyc",
    name: "KYC",
    component: () => import("@/views/KYC/Index.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/dashboard",
    name: "Dashboard",
    redirect: "/dashboard/wallets",
    component: () => import("@/views/Dashboard/DashboardWrapper.vue"),
    meta: {
      requiresAuth: true,
    },
    children: [
      {
        path: "/dashboard/wallets",
        name: "Wallets",
        component: () => import("@/views/Dashboard/Wallets/WalletsIndex.vue"),
      },
      {
        path: "/dashboard/swap",
        name: "Swap",
        component: () => import("@/views/Dashboard/Swap/SwapIndex.vue"),
      },
      {
        path: "/dashboard/exchange",
        name: "Exchange",
        component: () => import("@/views/Dashboard/Exchange/ExchangeIndex.vue"),
      },
      {
        path: "/dashboard/finance",
        name: "Finance",
        component: () => import("@/views/Dashboard/Finance/FinanceIndex.vue"),
        children: [
          {
            path: "/dashboard/finance",
            name: "Lending",
            component: () => import("@/views/Dashboard/Finance/Lend.vue"),
          },
          {
            path: "/dashboard/finance/stake",
            name: "CryptoLend",
            component: () => import("@/views/Dashboard/Finance/Stake.vue"),
          },
        ],
      },
      {
        path: "/dashboard/nft",
        name: "NFT",
        component: () => import("@/views/Dashboard/NFT/NFTIndex.vue"),
      },
      {
        path: "/dashboard/cold-wallet",
        name: "ColdWallet",
        component: () =>
          import("@/views/Dashboard/ColdWallet/ColdWalletIndex.vue"),
      },
      {
        path: "/dashboard/account",
        name: "Account",
        component: () => import("@/views/Dashboard/Account/AccountIndex.vue"),
        children: [
          {
            path: "/dashboard/account",
            name: "AccountSettings",
            component: () => import("@/views/Dashboard/Account/Settings.vue"),
          },
          {
            path: "/dashboard/account/security",
            name: "AccountSecurity",
            component: () => import("@/views/Dashboard/Account/Security.vue"),
          },
          {
            path: "/dashboard/account/notifications",
            name: "NotificationSettings",
            component: () =>
              import("@/views/Dashboard/Account/Notifications.vue"),
          },
          {
            path: "/dashboard/account/audit",
            name: "AuditReserves",
            component: () => import("@/views/Dashboard/Account/Audit.vue"),
          },
        ],
      },
      {
        path: "*",
        redirect: "/dashboard/wallets",
      },
    ],
  },
];
