<template>
 <div class="nyx-confirm-dialog">
  <div class="nyx-confirm-dialog__content"></div>
 </div>
</template>

<script>
export default {
 name: "ConfirmDialogue"
}
</script>

<style scoped>
.nyx-confirm-dialog {
 position: fixed;
 width: 100%;
 height: 100%;
 z-index: 999;
 top: 0;
 left: 0;
 overflow-y: auto;
 display: flex;
 align-items: flex-start;
 justify-content: center;
 padding: 4rem 1rem;
 background: rgba(0, 0, 0, 0.7);
 box-shadow: 0 10px 20px -10px rgb(0 0 0 / 20%);
 backdrop-filter: blur(5px);
 -webkit-backdrop-filter: blur(5px);
 transform: scale(1);
 transition: 0.2s ease-in-out;
}

.nyx-confirm-dialog .nyx-confirm-dialog__content {
 position: relative;
 height: auto;
 width: 100%;
 max-width: 500px;
 background: var(--baseWhite);
 z-index: 10;
 border-radius: var(--largeRadius);
 box-shadow: 0px 0px 16px #5064d280;
 border: 1px solid #f1f1f1;
 padding: 2rem;
}
</style>