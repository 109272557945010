var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{ref:"dropdownMenu"},[_c('div',{staticClass:"form-group mb-2 cursor--pointer",on:{"click":_vm.toggleDropdown}},[_c('div',{staticClass:"nx-input-group"},[(_vm.selectedWallet)?_c('div',{staticClass:"d-flex align-items-center me-2"},[_c('div',{staticClass:"me-1"},[_c('div',{staticClass:"nyx-wallet-icon nyx-wallet-icon--medium"},[_c('img',{directives:[{name:"img-loaded",rawName:"v-img-loaded",value:(
                require('@/assets/icons/placeholder/broken_coin_icon.svg')
              ),expression:"\n                require('@/assets/icons/placeholder/broken_coin_icon.svg')\n              "}],attrs:{"src":_vm.selectedWallet.logo,"crossorigin":"anonymous","alt":""}})])]),(!_vm.isDetailed)?_c('CryptoDropdownRegular',{attrs:{"activeWallet":_vm.selectedWallet}}):_vm._e(),(_vm.isDetailed)?_c('CryptoDropdownWithBalance',{attrs:{"activeWallet":_vm.selectedWallet}}):_vm._e()],1):_c('div',{staticClass:"d-flex align-items-center me-4"},[_vm._m(0),_c('div',{staticClass:"text__body--small text-is--dark text-is--regular text-capitalize"},[_vm._v(" "+_vm._s(_vm.$t(`message.generic.select`))+" ")])]),_c('div',{staticClass:"nx-input-group__icon"},[_c('img',{attrs:{"src":_vm.dropdownOpen
              ? _vm.AppAssets.ICONS.SearchSortIconReverse
              : _vm.AppAssets.ICONS.SearchSortIcon,"alt":""}})])])]),_c('div',{staticClass:"nyx-dropdown-menu",class:{
      'nyx-dropdown-menu--closed': !_vm.dropdownOpen,
      'nyx-dropdown-menu--open': _vm.dropdownOpen,
    }},[(_vm.wallets.length > 5)?_c('GenericSearchbox',{attrs:{"placeholder":"Search..."},model:{value:(_vm.searchTerm),callback:function ($$v) {_vm.searchTerm=$$v},expression:"searchTerm"}}):_vm._e(),_vm._l((_vm.cryptoWallets),function(wallet){return _c('div',{key:wallet.symbol,on:{"click":function($event){return _vm.chooseCoin(wallet)}}},[_c('CryptoDropdownItem',{attrs:{"wallet":wallet,"isActive":_vm.selectedWallet ? _vm.selectedWallet.symbol == wallet.symbol : false}})],1)})],2)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"me-2"},[_c('div',{staticClass:"nyx-wallet-icon nyx-wallet-icon--medium"},[_c('img',{attrs:{"src":require("@/assets/icons/WalletIconPlaceholder.svg"),"alt":""}})])])
}]

export { render, staticRenderFns }