<template>
  <div ref="dropdownMenu">
    <div class="form-group mb-2 cursor--pointer" @click="toggleDropdown">
      <div class="nx-input-group">
        <div class="d-flex align-items-center me-2" v-if="selectedWallet">
          <div class="me-1">
            <div class="nyx-wallet-icon nyx-wallet-icon--medium">
              <img
                :src="selectedWallet.logo"
                v-img-loaded="
                  require('@/assets/icons/placeholder/broken_coin_icon.svg')
                "
                crossorigin="anonymous"
                alt=""
              />
            </div>
          </div>
          <CryptoDropdownRegular
            :activeWallet="selectedWallet"
            v-if="!isDetailed"
          />
          <CryptoDropdownWithBalance
            :activeWallet="selectedWallet"
            v-if="isDetailed"
          />
        </div>
        <div class="d-flex align-items-center me-4" v-else>
          <div class="me-2">
            <div class="nyx-wallet-icon nyx-wallet-icon--medium">
              <img src="@/assets/icons/WalletIconPlaceholder.svg" alt="" />
            </div>
          </div>
          <div
            class="text__body--small text-is--dark text-is--regular text-capitalize"
          >
            {{ $t(`message.generic.select`) }}
          </div>
        </div>
        <div class="nx-input-group__icon">
          <img
            :src="
              dropdownOpen
                ? AppAssets.ICONS.SearchSortIconReverse
                : AppAssets.ICONS.SearchSortIcon
            "
            alt=""
          />
        </div>
      </div>
    </div>
    <div
      class="nyx-dropdown-menu"
      :class="{
        'nyx-dropdown-menu--closed': !dropdownOpen,
        'nyx-dropdown-menu--open': dropdownOpen,
      }"
    >
      <GenericSearchbox
        v-if="wallets.length > 5"
        placeholder="Search..."
        v-model="searchTerm"
      />
      <div
        v-for="wallet in cryptoWallets"
        :key="wallet.symbol"
        @click="chooseCoin(wallet)"
      >
        <CryptoDropdownItem
          :wallet="wallet"
          :isActive="
            selectedWallet ? selectedWallet.symbol == wallet.symbol : false
          "
        />
      </div>
    </div>
  </div>
</template>

<script>
import CryptoDropdownItem from "./CryptoDropdownItem";
import CryptoDropdownRegular from "./CryptoDropdownRegular";
import CryptoDropdownWithBalance from "./CryptoDropdownWithBalance";
export default {
  name: "CryptoDropdown",
  props: {
    isDetailed: Boolean,
    isCold: Boolean,
  },
  components: {
    CryptoDropdownItem,
    CryptoDropdownRegular,
    CryptoDropdownWithBalance,
  },
  data() {
    return {
      selectedWallet: null,
      dropdownOpen: false,
      coinSelected: false,
      searchTerm: "",
      wallets: [],
    };
  },
  computed: {
    coldWallets() {
      return this.$store.getters["coldWallet/allColdWallets"];
    },
    cryptoWallets() {
      let filtered = [...this.$store.getters["assets/cryptoAssets"]];
      filtered.sort((a, b) => a.name.localeCompare(b.name));
      if (this.searchTerm) {
        filtered = filtered.filter(
          (wallet) =>
            wallet.name.toLowerCase().includes(this.searchTerm.toLowerCase()) ||
            wallet.symbol.toLowerCase().includes(this.searchTerm.toLowerCase())
        );
      }
      if (this.selectedWallet) {
        filtered = filtered.sort((a, b) => {
          if (a.name === this.selectedWallet.name) {
            return -1;
          }
          if (b.name === this.selectedWallet.name) {
            return 1;
          }
          return 0;
        });
      }
      if (this.isCold && this.coldWallets.length > 0) {
        const coldWalletBalances = {};
        this.coldWallets.forEach((coldWallet) => {
          coldWalletBalances[coldWallet.assetSymbol] = coldWallet.quantity;
        });
        filtered = filtered
          .filter((cryptoWallet) => {
            return (
              typeof coldWalletBalances[cryptoWallet.symbol] !== "undefined"
            );
          })
          .map((cryptoWallet) => {
            return {
              ...cryptoWallet,
              symbol: cryptoWallet.symbol,
              balance: coldWalletBalances[cryptoWallet.symbol],
            };
          });
      }
      return filtered;
    },
  },
  mounted() {
    document.addEventListener("click", this.handleOutsideClick);
    this.getCryptoAssets();
  },
  beforeUnmount() {
    document.removeEventListener("click", this.handleOutsideClick);
  },
  methods: {
    async getCryptoAssets() {
      try {
        await this.$store.dispatch("assets/getCryptoAssets");
      } catch (error) {
        console.log(error);
      }
    },
    handleOutsideClick(event) {
      const dropdownMenu = this.$refs.dropdownMenu;
      if (!dropdownMenu || !dropdownMenu.contains(event.target)) {
        this.dropdownOpen = false;
      }
    },
    toggleDropdown() {
      this.dropdownOpen = !this.dropdownOpen;
    },
    chooseCoin(coin) {
      this.selectedWallet = coin;
      this.dropdownOpen = false;
      this.searchTerm = "";
      this.$emit("selected", coin);
    },
  },
};
</script>

<style></style>
