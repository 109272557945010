import store from "@/store";
import { API_BASE_URL } from "../config";
import router from "@/router";
import Axios from "axios";
const axiosInstance = Axios.create({
  baseURL: API_BASE_URL,
});
axiosInstance.interceptors.request.use(
  (config) => {
    if (
      store.state.auth &&
      store.state.auth.user != null &&
      store.state.auth.token
    ) {
      const token = store.state.auth.token;
      config.headers["Authorization"] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);
axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response && error.response.status) {
      const status = error.response.status;

      if (status === 401) {
        const currentRoute = router.currentRoute.fullPath;
        localStorage.setItem("lastVisited", JSON.stringify(currentRoute));
        router.push("/");
      }
    }

    return Promise.reject(error);
  }
);
export default axiosInstance;
