import axiosInstance from "@/http";
const state = {
  cryptoAssets: [],
  roraAssets: [],
  activeAsset: null,
  totalBalance: 0,
};
const getters = {
  cryptoAssets: (state) => state.cryptoAssets,
  roraAssets: (state) => state.roraAssets,
  activeAsset: (state) => state.activeAsset,
  totalBalance: (state) => state.totalBalance,
};
const mutations = {
  setActiveAsset(state, asset) {
    state.activeAsset = asset;
  },
  setCryptoAssets(state, assets) {
    state.cryptoAssets = assets;
  },
  setRoraAssets(state, assets) {
    state.roraAssets = assets;
  },
  setTotalBalance(state, balance) {
    state.totalBalance = balance;
  },
};

const actions = {
  async getRoraHoldings({ commit }, payload) {
    try {
      const response = await axiosInstance.get(`/assets/rora-assets`);
      const wallets = response.data;
      commit("setRoraAssets", wallets);
      // commit("setActiveAsset", wallets[0]);
      return response;
    } catch (error) {
      throw error;
    }
  },
  async getCryptoAssets({ commit }, payload) {
    try {
      const response = await axiosInstance.get(`/wallet`);
      const wallets = response.data.wallets;
      const formattedWallets = wallets.map((wallet) => ({
        ...wallet,
        symbol: wallet.network.assetSymbol,
        logo: wallet.network.logo,
        name: wallet.network.name,
        balance: parseFloat(wallet.balance).toFixed(3),
      }));
      commit("setCryptoAssets", formattedWallets);
      commit("setTotalBalance", response.data.totalBalance);
      commit("setActiveAsset", formattedWallets[0]);
      return response;
    } catch (error) {
      throw error;
    }
  },
  async generateWallets({ commit }, payload) {
    try {
      const response = await axiosInstance.get(`/wallet/generate`);
      return response;
    } catch (error) {
      throw error;
    }
  },
  async getWalletTransaction({ commit }, payload) {
    try {
      const response = await axiosInstance.get(
        `/wallet/${payload.id}?page=${payload.page}&limit=${payload.limit}`
      );
      return response;
    } catch (error) {
      throw error;
    }
  },
  async estimateFee({ commit }, payload) {
    try {
      const response = await axiosInstance.post(`/wallet/estimatefee`, payload);
      return response;
    } catch (error) {
      throw error;
    }
  },
  async sendCrypto({ commit }, payload) {
    try {
      const response = await axiosInstance.post(
        `/wallet/sendTransaction`,
        payload
      );
      return response;
    } catch (error) {
      throw error;
    }
  },
  async toggleFavourite({ commit }, payload) {
    try {
      const response = await axiosInstance.put(`/wallet`, payload);
      return response;
    } catch (error) {
      throw error;
    }
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
};
