<template>
 <div class="nyx-spinner" :class="{
  'nyx-spinner--large': isLarge,
  'mx-auto': isLarge,
 }"></div>
</template>

<script>
export default {
 name: "InlineLoader",
 props: {
  isLarge: {
   type: Boolean,
   default: false
  }
 }
}
</script>

<style>
.nyx-spinner {
 width: 13px;
 height: 13px;
 border-radius: 50%;
 border: 1px solid rgba(0, 0, 0, 0.1);
 border-top-color: #fff;
 animation: spin 0.8s linear infinite;
}

.nyx-spinner.nyx-spinner--large {
 width: 30px;
 height: 30px;
}

@keyframes spin {
 to {
  transform: rotate(360deg);
 }
}
</style>