<template>
 <div class="d-flex align-items-center">
  <div class="text__body--small text-is--dark text-is--semibold me-3">{{ wallet.name }}</div>
  <div class="text__body--smaller text-is--light text-is--dark text-uppercase">{{ wallet.symbol }}</div>
 </div>
</template>

<script>
export default {
 name: "CryptoDropdownRegular",
 props: {
  activeWallet: Object
 },
 computed: {
  wallet() {
   return this.activeWallet;
  }
 }
}
</script>

<style></style>