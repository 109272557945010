<template>
  <div
    class="auth-header d-flex align-items-center justify-content-center py-4"
  >
    <img
      :src="AppAssets.BRAND.FullWhiteLogo"
      alt="NYX Exchange logo"
      class="auth-header__logo"
    />
  </div>
</template>

<script>
import "@/styles/auth.css";
export default {
  name: "AuthHeader",
};
</script>

<style></style>
