const state = {
  exchangePlatforms: [
    {
      name: "Binance",
      logo: require("@/assets/icons/platforms/binance.png"),
      taker: "0.5%",
      maker: "0.5%",
      price: 50,
      isFavourite: true,
    },
    {
      name: "AscendEX",
      logo: require("@/assets/icons/platforms/ascend.png"),
      taker: "0.5%",
      maker: "0.5%",
      price: 50,
      isFavourite: false,
    },
    {
      name: "Bitstamp",
      logo: require("@/assets/icons/platforms/bitstamp.png"),
      taker: "0.6%",
      maker: "0.5%",
      price: 50,
      isFavourite: false,
    },
    {
      name: "Bitbay",
      logo: require("@/assets/icons/platforms/bitbay.png"),
      taker: "0.2%",
      maker: "0.5%",
      price: 50,
      isFavourite: false,
    },
    {
      name: "Blockchain",
      logo: require("@/assets/icons/platforms/blockchain.png"),
      taker: "0.2%",
      maker: "0.5%",
      price: 50,
      isFavourite: true,
    },
    {
      name: "Kraken",
      logo: require("@/assets/icons/platforms/kraken.png"),
      taker: "0.5%",
      maker: "0.8%",
      price: 50,
      isFavourite: false,
    },
    {
      name: "KuCoin",
      logo: require("@/assets/icons/platforms/kucoin.png"),
      taker: "0.1%",
      maker: "0.5%",
      price: 50,
      isFavourite: false,
    },
  ],
  activePlatform: {
    name: "Binance",
    logo: require("@/assets/icons/platforms/binance.png"),
    taker: "0.5%",
    maker: "0.5%",
    price: 50,
    isFavourite: true,
  },
};
const getters = {
  currentActivePlatform(state) {
    return state.activePlatform;
  },
  allPlatforms: (state) => state.exchangePlatforms,
};
const mutations = {
  setActivePlatform(state, platform) {
    state.activePlatform = platform;
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  getters,
};
