export default {
  message: {
    routeName: {
      wallets: "Wallets",
      exchange: "Exchange",
      swap: "Swap",
      finance: "Finance",
      lending: "Lending",
      "crypto Lend": "Staking",
      "cold Wallet": "Cold Wallet",
      "account Settings": "Account Settings",
      "account Security": "Account Security",
      "notification Settings": "Notification Settings",
    },
    login: {
      title: "Account Login",
      email: "Enter Email Address",
      password: "Enter Your password",
      validation_message: [
        "Be sure to provide a valid email",
        "Password Should Be at least 8 characters",
      ],
      forgot_password: "Forgot Password?",
      no_account: "Don't have an account?",
      create_new: "Create new",
      login_button: "Login",
    },
    register: {
      title: "Create Account",
      description: "Create your account to get started",
      email_placeholder: "Email Address",
      password_placeholder: "Create strong password",
      email_error_message: "Please enter a valid email",
      password_error_message: "Password Should Be at least 8 characters",
      agree_marketing_label: "I agree to share data for marketing purposes.",
      email_updates_label: "I agree to receive email updates from NYX Exchange",
      submit_button_text: "Next",
      submit_button_loading_text: "Loading...",
      have_account_message: "Already have an account?",
      login_link_text: "Login",
      prompt:
        "Thank you for registering with NYX Exchange. To ensure your account is saved and your signin works seamlessly, please complete the entire process, including uploading your ID. Failure to do so may result in your account not being saved and your signin not functioning properly.",
      otpMessage:
        "You will have 60 seconds to insert your OTP. If you don't complete it within the given time, the code will expire, and you will need to resend it.",
    },
    emailVerification: {
      title: "Email Verification",
      description:
        "Please enter the 6-digit verification code that was sent to {email}. The code is valid for 30 minutes.",
      enterVerificationCode: "Enter verification code",
      resendCodeIn: "Resend Code in",
      resendCode: "Resend Code",
      nextButton: "Next",
    },
    enterPhoneNumber: {
      title: "Enter Phone Number",
      description: "Provide your phone number for further verification",
      placeholder: "Phone Number",
      nextButton: "Next",
    },
    phoneVerification: {
      title: "Phone Verification",
      description:
        "Please enter the 6-digit verification code that was sent to {phone}. The code is valid for 30 minutes.",
      enterVerificationCode: "Enter verification code",
      resendCodeIn: "Resend Code in",
      resendCode: "Resend Code",
      nextButton: "Next",
    },
    registrationSteps: {
      create_account: "Create Account",
      verify_email: "Verify Email",
      phone_number: "Phone Number",
      verify_phone: "Verify Phone",
    },
    forgotPassword: {
      title: "Forgot Password",
      description: "Enter email to reset password",
      placeholder: "Email Address",
      validationMessage: "Please enter a valid email",
      goBack: "Go back",
      nextButton: "Next",
    },
    resetOption: {
      title: "Forgot Password",
      description: "Select recovery method below.",
      sendToMobile: {
        title: "Send to Mobile",
        description: "Send a unique code to your mobile number.",
      },
      sendToEmail: {
        title: "Send to Email",
        description: "Send a unique code to your email address.",
      },
      rememberPassword: "Remember password?",
      loginLink: "Login",
    },
    resetPhoneOptions: {
      title: "Forgot Password",
      description: "Enter phone number to reset password",
      placeholder: "Phone Number",
      goBack: "Go back",
      nextButton: "Next",
      rememberPassword: "Remember password?",
      loginLink: "Login",
    },
    resetPassword: {
      title: "Reset Password",
      description: "Choose a new password for your account",
      placeholder: "Create strong password",
      repeatPasswordPlaceholder: "Repeat password",
      passwordErrorMessage: "Password should be at least 8 characters",
      passwordsDoNotMatchError: "Passwords do not match",
      resetButton: "Reset",
    },
    otpVerification: {
      title: "OTP Verification",
      description:
        "Please enter the 6-digit verification code that was sent to",
      resendCodeIn: "Resend Code in",
      resendCode: "Resend Code",
      enterVerificationCode: "Enter verification code",
      nextButton: "Next",
    },
    dashboardMenu: {
      wallets: "Wallets",
      exchange: "Exchange",
      swap: "Swap",
      finance: "Finance",
      cold_wallet: "Cold Wallet",
    },
    lending: {
      p2p: {
        title: "P2P Lending",
        description:
          "Looking for a rapid loan or eager to gain interest on your cryptocurrency? Look no further, because this is the perfect spot!",
      },
      stake: {
        title: "Crypto Stake",
        description:
          "Stake some of your crypto and earn rewards up to 14.5% p.a. especially for stablecoins.",
      },
    },
    cold_wallet: {
      understand: "Understand about Cold Wallet",
      regular: {
        title: "Regular crypto wallet",
        description:
          "Do any transactions like trading, instant exchange, send and receive with your regular wallet. Secure your assets from fraud / hackers with Cold Wallet.",
      },
      cold: {
        title: "Cold Wallet",
        description:
          "Cold storage can be performed through offline software wallets, where a dual authentication process is completed. The public key remains on the online platform while an isolated environment generates the private keys for optimal security.",
      },
      safety: {
        title: "Extra safety for your assets",
        description:
          "Extra safety for your assets Cryptocurrency is most at risk when stored online, thus making cold storage the ideal option for anyone wanting to securely preserve large amounts of crypto-assets in the long run - from steadfast Hodlers (investors who purchase and hang on to their holdings no matter market price) to establishments that have millions of dollars invested.",
      },
    },
    darkpoolSumarry: [
      "Only limit orders are supported for Dark Pool.",
      "The minimum order size for BTC pairs is equivalent to roughly 100k USD.",
      "The minimum order size for ETH pairs is equivalent to roughly 50k USD.",
    ],
    generic: {
      wallets: "Wallets",
      search: "Search",
      pool: "Pool",
      send: "Send",
      receive: "Receive",
      swap: "Swap",
      description: "Description",
      transactions: "Transactions",
      history: "History",
      high: "High",
      low: "Low",
      selected: "Selected",
      pair: "Pair",
      last_price: "Last Price",
      volume: "Volume",
      activity: "Activity",
      balance: "Balance",
      market_order: "Market Order",
      limit_order: "Limit Order",
      buy: "Buy",
      sell: "Sell",
      amount: "Amount",
      price: "Price",
      set_alert: "Set Alert",
      select: "Select",
      exchange: "Exchange",
      from: "From",
      to: "to",
      total: "Total",
      active: "active",
      you: "you",
      your: "your",
      have: "have",
      lend: "lend",
      loan: "loan",
      repay: "repay",
      lending: "lending",
      closed: "closed",
      risk: "risk",
      calculate: "calculate",
      rewards: "rewards",
      staking: "staking",
      yield: "yield",
      no: "no",
      stake: "stake",
      previous: "previous",
      add: "Add",
      account: "Account",
      documents: "Document",
      unique: "Unique User ID",
      language: "Language",
      withdrawal: "Withdrawal",
      deposit: "Deposit",
      sending: "You are sending",
      authenticator: "Google Authenticator Code",
      pin: "Pin",
      enter: "Enter",
      optional: "Optional",
      network: "Network",
      fee: "Fee",
      crypto: "Crypto",
      view_on_blockchain: "View Link on Blockchain",
      change: "Change",
      portfolio: "Portfolio",
      age: "Age",
      best: "Best",
      asset: "Asset",
      highest: "Highest",
      date: "Date",
      time: "Time",
      transaction_type: "Transaction Type",
      current_price: "Current Price",
      months: "Months",
      year: "Year",
      current: "Current",
      action: "Action",
      no_transactions: "No Transactions to show",
      filled: "Filled",

      other: "Other",
      trans_activity: "Transaction Activity",
      dark_pool: "Dark Pool",
      pending: "Pending",
      completed: "Completed",
      error: "An error occurred while fetching data",
      try_again: "Try Again",
      swap_in_progress: "Swap In Progress",
      swap_summary:
        "Your transaction in progress, it will take 30 minutes or less to arrive in your balance. Feel free to use NYX Exchange app while waiting this progress, and you'll be notified after transaction is  complete.",
      borrow: "Borrow",
      interest: "Interest",
      rate: "Rate",
      duration: "Duration",
      repayment: "Repayment",
      accept_lend:
        "By accepting this request, I agree with loan  payments Term & Conditions. NYX Exchange is not responsible for this lending transaction. Don't loan who you don't trust",
      accept: "Accept",
      delete: "Delete",
      return_in: "Return In",
      debt: "Debt",

      liquidation: "liquidation",
      export: "export",
      back: "back",
      paid: "paid",
      post: "post",
      request: "request",
      agree_post_loan:
        "By posting this request, I agree with loan payments Term & Conditions",
      earn: "earn",
      borrower: "borrower",
      plan: "plan",
      on: "on",
      stable_coins: "Stable Coins",
      new: "new",
      up: "up",
      token: "token",
      term: "term",
      per: "per",
      weekly: "weekly",
      for: "for",

      withdraw: "withdraw",
      welcome: "Welcome",
      continue: "Continue",
      dark_pool: "Dark Pool",

      logout: "Logout",
      security: "Security",
      notifications: "Notifications",
      id_card: "Identity Card",
      proof_of_residence: "Proof of residence",
      general: "General",
      two_fa: "2FA Settings",
      password: "Password",
      create: "Create",

      repeat: "Repeat",
      same_password: "New Password Cannot be same as old password",
      no_match_password: "Passwords do not match",
      coming_soon: "Coming Soon",
      verified: "Verified",
    },
    location: {
      popup:
        "Thank you for your interest in the NYX Exchange. Unfortunately, we are currently unable to provide service in your country. We encourage you to check back regularly as we expand our reach and acquire new licenses to serve more countries.",
    },
    two_fa: {
      recommended: "Recommended",
      google: "Google Authenticator App",
      activate: "Activate",
      deactivate: "Deactivate",
      summary:
        "Protect your account by requiring an additional layer of security to sign in.",
      scan_message: "Open Your Google Auth App and Scan the Code below",
      withdraw: {
        title: "Withdraw authentication",
        description:
          "Protect your funds by using a 2FA confirmation every time you withdraw funds or generate a new deposit address.",
      },
    },
    ai_notifications: {
      title: "A.I notifications",
      description:
        "Send push notification about crypto prices on the market based on your interest with smart algorithm.",
    },
    admin_emails: {
      title: "Administrative emails",
      description:
        "Non-critical updates that may affect your NYX Exchange account (e.g., new features or services, policy updates, etc.)",
    },
    newsletter_emails: {
      title: "Newsletter emails",
      description:
        "Helpful information that you may find interesting (e.g., blog posts, analysis and interesting facts about the Bitcoin industry, special offers, etc.)",
    },
    common: {
      on: "ON",
      off: "OFF",
    },
    kyc: {
      kyc_complete_action: "Your KYC Documents will appear here when approved.",
      kyc_in_progress:
        "  KYC Verification in progress. You will be notified when your account has been verified",
      kyc_not_started:
        "  Verify your account here. Webcam is required, either you can do this on your phone or enable Webcam on your PC.",
      header: {
        buttonText: "Click Here",
        message: "to complete KYC Verification and fully activate your account",
      },
      popup:
        "Thank you for joining NYX Exchange! The following pages are dedicated to verifying your identity, enabling you to fully utilize the exchange and trade with freedom. We have partnered with ShuftiPro, a trusted third-party KYC Provider, to securely store all personal data on their servers. Should you ever wish to cancel your account, please contact NYX Exchange at support@nyxex.com, and we will promptly request ShuftiPro to remove your information from their servers. Additionally, we will ensure that all of your personal data, except for what is legally required to be retained, is completely wiped from our servers. Once again, thank you for choosing NYX Exchange. We look forward to serving you!",
      identityVerification: {
        title: "Verify Your Identity",
        fullName: "Full Name",
        nameErrorMessage: "Name should be at least 5 characters",
        dateOfBirth: "Date of Birth",
        address: "Address",
        addressErrorMessage: "Address should be at least 5 characters",
        city: "City",
        cityErrorMessage: "City should be at least 3 characters",
        country: "Country",
        currency: "Currency",
        agreePrivacyPolicy: "I agree to NYX Exchange Privacy & Policy.",
        privacyPolicyLink: "Privacy & Policy",
        submitButton: "Next",
        isLoadingText: "Loading...",
      },
      proofOfResidence: {
        title: "Proof of Residence",
        tipsTitle: "Tips:",
        tips: [
          "Tip 1: Provide a clear and readable document",
          "Tip 2: Ensure the document shows your current residential address",
          "Tip 3: Avoid any glare or reflections on the document",
        ],
        buttonText: "Submit",
      },
      takePhotoOfID: {
        title: "Take Photo of Your ID",
        tipsTitle: "Accepted Document:",
        tips: [
          "Tip 1: Ensure the document is clear and readable",
          "Tip 2: Capture the entire ID within the frame",
          "Tip 3: Avoid glare or reflections on the document",
        ],
        buttonText: "Submit",
      },
      takePhotoOfYou: {
        title: "Take Photo of You",
        tipsTitle: "Tips:",
        tips: [
          "Tip 1: Find a well-lit area for better photo quality.",
          "Tip 2: Remove any accessories that may obstruct your face.",
        ],
        buttonText: "Submit",
      },
      selfieWithYourID: {
        title: "Selfie with Your ID",
        tipsTitle: "Tips:",
        tips: [
          "Tip 1: Ensure your face and ID are well-lit for a clear photo.",
          "Tip 2: Remove any accessories that may obstruct your face or ID.",
        ],
        buttonText: "Submit",
      },
      waitingForApproval: {
        title: "Waiting for Approval",
        description:
          'Thank you for choosing NYX Exchange for your crypto needs. Your account has been registered, and your documents uploaded. You can now log in and review the exchange dashboard, as well as move crypto in and out of your wallets. If you\'re interested in buying or selling crypto, please click on the "Buy" or "Sell" option in the exchange tab and complete the basic KYC process for immediate approval. Thank you once again, and please do not hesitate to reach out to us at support@morizonx.com if you require any further assistance.',
        closeButton: "Close",
      },
    },
    wallet: {
      sendCaution:
        "Warning: Only send the specified token to this address; sending any other token may result in irreversible loss. Verify and proceed with caution.",
    },
    exchange: {
      prompt: `
      <p>Thank you for registering with the NYX Exchange.</p>
      <p>For those of you who have reserved NYX Exchange Shares, you can now make payment today.</p>
      <p>The current price is £63 per Share, which you will need to convert to $USD by using <a href="https://xe.com" target="_blank">https://xe.com</a> currency converter.</p>
      <p>Payment must be made in USDT to the following USDT ERC-20 wallet address:</p>
      <p><strong>0x8DD4F5034D97602aAef43c42bC22269FAF6f356B</strong></p>
      <p>If you have USDT TRC-20, you will first need to convert it to USDT ERC-20 by using a swapping engine like <a href="https://simpleswap.io" target="_blank">https://simpleswap.io</a>.</p>
      <p>Once converted, you will be able to send your payment to the NYX Exchange USDT ERC-20 wallet address:</p>
      <p><strong>0x8DD4F5034D97602aAef43c42bC22269FAF6f356B</strong></p>
      <p>Please insert your First & Last Name in the 'Personal Note' section of the 'Send Form' when making your payment.</p>
      <p>If you need assistance, please send an email to <a href="mailto:shares@morizonbaank.com">shares@morizonbaank.com</a>.</p>
      <p>Thank you for your support,</p>
      <p>The NYX Exchange Team</p>
    `,
    },
  },
};
