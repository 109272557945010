import Vue from "vue";
Vue.directive("numeric", {
  bind(el) {
    const numericOnlyRegex = /^([0-9]*[.]?[0-9]*)$/;
    el.addEventListener("input", function (event) {
      const currentValue = event.target.value;
      const numericValue = currentValue.match(numericOnlyRegex);
      const sanitizedValue = numericValue ? numericValue[0] : "";
      if (currentValue !== sanitizedValue) {
        event.target.value = sanitizedValue;
        event.target.dispatchEvent(new Event("input"));
      }
    });
    el.addEventListener("blur", function (event) {
      let currentValue = event.target.value;
      const numericValue = currentValue.match(numericOnlyRegex);
      const sanitizedValue = numericValue ? numericValue[0] : "";
      if (!sanitizedValue.trim()) {
        sanitizedValue = "";
      }
      if (currentValue !== sanitizedValue) {
        event.target.value = sanitizedValue;
        event.target.dispatchEvent(new Event("input"));
      }
    });
  },
});
// Image loader
Vue.directive("img-loaded", {
  inserted: function (el, binding) {
    loadImage(el, binding.value);
  },
  update: function (el, binding) {
    loadImage(el, binding.value);
  },
});

function loadImage(el, placeholderUrl) {
  let img = new Image();
  el.crossOrigin = "anonymous";
  img.crossOrigin = "anonymous";
  img.src = el.src;
  img.onload = function () {
    el.classList.remove("nyx-placeholder");
  };
  img.onerror = function () {
    el.src = placeholderUrl;
    el.classList.add("nyx-placeholder");
  };
}
Vue.directive("maxlength", {
  bind: function (el, binding) {
    let maxLength = binding.value;
    el.addEventListener("input", function (event) {
      if (event.target.value.length > maxLength) {
        event.target.value = event.target.value.slice(0, maxLength);
        event.target.dispatchEvent(new Event("input"));
      }
    });
  },
});
