// Import Components
import Vue from "vue";
import VueMeta from "vue-meta";
import Vuelidate from "vuelidate";
import Toast from "vue-toastification";
import { Datetime } from "vue-datetime";
import Select2 from "v-select2-component";
import vSelect from "vue-select";
import OtpInput from "@bachdgvn/vue-otp-input";
import VueCountryCode from "vue-country-code";
import {
  OtpCountdown,
  ModalWrapper,
  CryptoSelect,
  CryptoDropdown,
  TextDropdown,
  GenericWalletCard,
  GenericSearchbox,
  Checkbox,
  InlineError,
  InlineLoader,
  SectionLoader,
  ConfirmDialogue,
} from "@/components";

// Customize Options
const ToastOptions = {
  timeout: 8000,
  pauseOnFocusLoss: false,
  hideProgressBar: true,
  position: "top-center",
  transition: "Vue-Toastification__fade",
};

// Use Components
Vue.component("select-dropdown", Select2);
Vue.component("v-select", vSelect);
Vue.component("otp", OtpInput);
Vue.component("country-code", VueCountryCode);
Vue.use(Toast, ToastOptions);
Vue.component("datetime", Datetime);
Vue.component("OtpCountdown", OtpCountdown);
Vue.component("ModalWrapper", ModalWrapper);
Vue.component("CryptoSelect", CryptoSelect);
Vue.component("CryptoDropdown", CryptoDropdown);
Vue.component("TextDropdown", TextDropdown);
Vue.component("GenericWalletCard", GenericWalletCard);
Vue.component("GenericSearchbox", GenericSearchbox);
Vue.component("Checkbox", Checkbox);
Vue.component("InlineError", InlineError);
Vue.component("InlineLoader", InlineLoader);
Vue.component("SectionLoader", SectionLoader);
Vue.component("ConfirmDialogue", ConfirmDialogue);
Vue.use(Vuelidate);
Vue.use(VueMeta, {
  refreshOnceOnNavigation: true,
});
