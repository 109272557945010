import Vue from "vue";
import VueI18n from "vue-i18n";
import { en, la_es, fr, pt_br } from "./availableLocales";
Vue.use(VueI18n);
const i18n = new VueI18n({
  locale: "en",
  messages: {
    en,
    la_es,
    fr,
    pt_br,
  },
});
export default i18n;
