import axiosInstance from "@/http";
const state = {};

const getters = {};

const mutations = {};

const actions = {
  // Dashboard
  async getDashboard({ commit }) {
    try {
      const response = await axiosInstance.get(`/dashboard`);
      return response;
    } catch (error) {
      throw error;
    }
  },
  async verifyUserId({ commit }, payload) {
    try {
      const response = await axiosInstance.put(
        `/user/verify-user-identity/${payload._id}`,
        payload.data
      );
      return response;
    } catch (error) {
      throw error;
    }
  },
  async updateKycStatus({ commit }, payload) {
    try {
      const response = await axiosInstance.post(
        `/user/update-kyc-status`,
        payload
      );
      return response;
    } catch (error) {
      throw error;
    }
  },
  async uploadPhoto({ commit }, payload) {
    try {
      const response = await axiosInstance.put(
        `/user/upload-photo/${payload._id}`,
        payload.formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      return response;
    } catch (error) {
      throw error;
    }
  },
  async uploadPhotoID({ commit }, payload) {
    try {
      const response = await axiosInstance.put(
        `/user/upload-id-card/${payload._id}`,
        payload.formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      return response;
    } catch (error) {
      throw error;
    }
  },
  async uploadSelfie({ commit }, payload) {
    try {
      const response = await axiosInstance.put(
        `/user/upload-photo-with-id-card/${payload._id}`,
        payload.formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      return response;
    } catch (error) {
      throw error;
    }
  },
  async uploadProofOfResidence({ commit }, payload) {
    try {
      const response = await axiosInstance.put(
        `/user/upload-proof-of-residence/${payload._id}`,
        payload.formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      return response;
    } catch (error) {
      throw error;
    }
  },
  // Notifications
  async updateAiNotifications({ commit }, payload) {
    try {
      const response = await axiosInstance.put(
        `/user/update-ai-notification-status/${payload._id}`,
        payload.data
      );
      return response;
    } catch (error) {
      throw error;
    }
  },
  async updateAdminNotifications({ commit }, payload) {
    try {
      const response = await axiosInstance.put(
        `/user/update-administrative-email-status/${payload._id}`,
        payload.data
      );
      return response;
    } catch (error) {
      throw error;
    }
  },
  async updateNewsletterNotifications({ commit }, payload) {
    try {
      const response = await axiosInstance.put(
        `/user/update-newsletter-status/${payload._id}`,
        payload.data
      );
      return response;
    } catch (error) {
      throw error;
    }
  },
  // Update TWOFA
  async activateGoogleAuth({ commit }) {
    try {
      const response = await axiosInstance.post(`/auth/2fa/generate-key`);
      return response;
    } catch (error) {
      throw error;
    }
  },
  async updateGoogleAuth({ commit }, payload) {
    try {
      const response = await axiosInstance.put(
        `/user/update-2FA-google-authenticator/${payload._id}`,
        payload.data
      );
      return response;
    } catch (error) {
      throw error;
    }
  },
  async updateWithdraw2fa({ commit }, payload) {
    try {
      const response = await axiosInstance.put(
        `/user/update-2FA-withdraw/${payload._id}`,
        payload.data
      );
      return response;
    } catch (error) {
      throw error;
    }
  },
  // Documents
  async getDocumentNames({ commit }, userId) {
    try {
      const response = await axiosInstance.get(
        `/user/get-documents-name/${userId}`
      );
      return response;
    } catch (error) {
      throw error;
    }
  },
  // Pin
  async doesPinExist({ commit }) {
    try {
      const response = await axiosInstance.get(`/user/pin/is-valid`);
      return response;
    } catch (error) {
      throw error;
    }
  },
  async createPin({ commit }, payload) {
    try {
      const response = await axiosInstance.post(`/user/pin`, payload);
      return response;
    } catch (error) {
      throw error;
    }
  },
  async changePin({ commit }, payload) {
    try {
      const response = await axiosInstance.put(`/user/pin/update`, payload);
      return response;
    } catch (error) {
      throw error;
    }
  },
  // Password
  async changePassword({ commit }, payload) {
    try {
      const response = await axiosInstance.put(
        `/user/password/update`,
        payload
      );
      return response;
    } catch (error) {
      throw error;
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
