<template>
  <div class="nx-language-change">
    <div>
      <select
        v-model="selectedLanguage"
        @change="doTranslation"
        class="text-capitalize"
      >
        <option v-for="item in country" :key="item.code" :value="item.code">
          {{ item.title }}
        </option>
      </select>
    </div>

    <div id="google_translate_element2"></div>
  </div>
</template>

<script>
export default {
  name: "GoogleTranslate",
  data() {
    return {
      selectedLanguage: "en",
      country: [
        { code: "fr", title: "French" },
        { code: "pt", title: "Brazilian Portuguese" },
        { code: "en", title: "English" },
        { code: "es", title: "Latin American Spanish" },
      ],
    };
  },
  mounted() {
    const lang = localStorage.getItem("lang");
    this.selectedLanguage = lang || "en";
  },
  computed: {
    currentRouteName() {
      return this.$route.path === "/"
        ? this.$route.path
        : `${this.$route.path}`;
    },
  },

  methods: {
    async doTranslation() {
      console.log(this.selectedLanguage);
      const code = this.selectedLanguage;
      localStorage.setItem("lang", code);

      const lang = await localStorage.getItem("lang");
      this.$router
        .replace(`${this.currentRouteName}#googtrans(${lang})`)
        .catch((err) => err);
      await this.$nextTick();
      setTimeout(() => {
        window.location.reload();
      }, 500);

      return false;
    },
  },
};
</script>
