<template>
 <div class="section-loader"></div>
</template>

<script>
export default {
 name: "SectionLoader"
}
</script>

<style scoped>
.section-loader {
 display: block;
 width: 100%;
 height: 4px;
 background-color: var(--fixedGrey);
 position: absolute;
 overflow: hidden;
 top: 0;
 left: 0;
}

.section-loader::before {
 content: "";
 display: block;
 position: absolute;
 left: -200px;
 width: 200px;
 height: 4px;
 background-color: var(--primaryPurple);
 animation: loader 2s linear infinite;
}

@keyframes loader {
 from {
  left: -200px;
 }

 to {
  left: 100%;
 }
}
</style>