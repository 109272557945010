<template>
  <div class="mobile-menu" v-if="showMobileMenu">
    <a
      href="javascript:void(0)"
      @click="closeMobileMenu"
      class="nyx-text-icon nyx-text-icon--large mobile-menu__close"
    >
      <i class="bi bi-x-square"></i>
    </a>
    <div class="mobile-menu__content">
      <div class="d-flex justify-content-center mb-4">
        <img :src="AppAssets.BRAND.FullWhiteLogo" class="brand-logo" alt="" />
      </div>
      <div v-for="item in menuItems" :key="item.id" class="mobile-menu__item">
        <router-link
          :to="item.path"
          class="mobile-menu__item-link d-flex align-items-center"
        >
          <div class="me-3">
            <img :src="item.icon" class="mobile-menu__item-link-icon" alt="" />
          </div>
          <div>
            {{
              item.id == "account"
                ? $t("message.generic.account")
                : $t(`message.dashboardMenu.${item.id}`)
            }}
          </div>
        </router-link>
      </div>
      <div class="mobile-menu__item">
        <a
          href="javascript:void(0)"
          @click="showComingSoon"
          class="mobile-menu__item-link d-flex align-items-center"
        >
          <div class="me-3">
            <img :src="NFTIcon" class="mobile-menu__item-link-icon" alt="" />
          </div>
          <div>NFT</div>
        </a>
      </div>
      <div class="horizontal-line"></div>
      <div class="mobile-menu__item">
        <button
          class="mobile-menu__item-link d-flex align-items-center"
          @click="logoutUser"
        >
          <div class="me-3">
            <img :src="LogoutIcon" class="mobile-menu__item-link-icon" alt="" />
          </div>
          <div>{{ $t("message.generic.logout") }}</div>
        </button>
      </div>
      <div class="px-3 d-flex align-items-start justify-content-start">
        <ThemeSwitch @themeSwitched="closeMobileMenu" />
      </div>
    </div>
  </div>
</template>

<script>
import WalletIcon from "@/assets/menuIcons/Wallets.svg";
import ExchangeIcon from "@/assets/menuIcons/Exchange.svg";
import SwapIcon from "@/assets/menuIcons/Swap.svg";
import FinanceIcon from "@/assets/menuIcons/Finance.svg";
import NFTIcon from "@/assets/menuIcons/NFT.svg";
import ColdWalletIcon from "@/assets/menuIcons/ColdWallet.png";
import NotificationsIcon from "@/assets/menuIcons/Notifications.svg";
import AccountIcon from "@/assets/menuIcons/Account.svg";
import LogoutIcon from "@/assets/menuIcons/Logout.svg";
import ThemeSwitch from "./ThemeSwitch.vue";
export default {
  name: "MobileMenu",
  components: { ThemeSwitch },
  data() {
    return {
      ColdWalletIcon,
      NotificationsIcon,
      AccountIcon,
      LogoutIcon,
      NFTIcon,
      menuItems: [
        {
          id: "wallets",
          name: "Wallets",
          icon: WalletIcon,
          path: "/dashboard/wallets",
        },
        {
          id: "exchange",
          name: "Exchange",
          icon: ExchangeIcon,
          path: "/dashboard/exchange",
        },
        {
          id: "swap",
          name: "Swap",
          icon: SwapIcon,
          path: "/dashboard/swap",
        },
        {
          id: "finance",
          name: "Finance",
          icon: FinanceIcon,
          path: "/dashboard/finance",
        },
        // {
        //  id: "nft",
        //  name: "NFT",
        //  icon: NFTIcon,
        //  path: '/dashboard/nft'
        // },
        {
          id: "cold_wallet",
          name: "Cold Wallet",
          icon: ColdWalletIcon,
          path: "/dashboard/cold-wallet",
        },
        {
          id: "account",
          name: "Account",
          icon: AccountIcon,
          path: "/dashboard/account",
        },
      ],
      showMobileMenu: false,
    };
  },
  methods: {
    openMobileMenu() {
      this.showMobileMenu = true;
    },
    closeMobileMenu() {
      this.showMobileMenu = false;
    },
    logoutUser() {
      this.$store.dispatch("auth/logout");
      this.$router.push("/auth/login");
    },
    showComingSoon() {
      this.$toast.info("Coming Soon...");
    },
  },
  watch: {
    $route(to, from) {
      this.closeMobileMenu();
    },
  },
};
</script>

<style>
.mobile-menu {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100%;
  z-index: 100;
  background: rgba(0, 0, 0, 0.7);
  box-shadow: 0 10px 20px -10px rgb(0 0 0 / 20%);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
}

.mobile-menu .mobile-menu__close {
  position: fixed;
  top: 2rem;
  right: 1rem;
}

.mobile-menu .mobile-menu__content {
  position: relative;
  max-width: 260px;
  height: 100vh;
  background-color: var(--primaryPurple);
  padding: 1.4rem 0;
}

.mobile-menu__item {
  margin-bottom: 0.6rem;
}

.mobile-menu__item .mobile-menu__item-link {
  font-size: 1rem;
  color: var(--fixedWhite);
  text-decoration: none;
  padding: 0.8rem 1rem;
  background: transparent;
  border: none;
}

.mobile-menu__item .mobile-menu__item-link-icon {
  width: 18px;
  height: 18px;
  display: block;
  object-fit: contain;
}

.mobile-menu__item-link.router-link-exact-active,
.mobile-menu__item-link.router-link-active {
  box-shadow: inset 0px 3px 6px #0730604d;
  background: rgb(255, 255, 255, 0.11);
}
</style>
