<template>
 <div class="has--base-shadow has--medium-radius has--large-radius bg--base-white position-relative overflow-hidden"
  :class="{
   'no-top-left-radius': clearTopLeftRadius,
   'no-top-radius': clearTopRadius,
   'py-2': clearVPaddings,
   'py-5': !clearVPaddings,
  }">
  <slot></slot>
 </div>
</template>

<script>
export default {
 name: "GenericWalletCard",
 props: {
  clearTopLeftRadius: Boolean,
  clearTopRadius: Boolean,
  clearVPaddings: Boolean,
 }
}
</script>

<style></style>