var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{ref:"notificationList"},[(!_vm.isMobile)?_c('button',{ref:"notificationButton",staticClass:"left-sidebar__item-action notification-toggle",on:{"click":_vm.toggleDropdown}},[(_vm.unread.hasUnread)?_c('div',{staticClass:"notification-toggle__unread"},[_c('span',[_vm._v(_vm._s(_vm.unread.count))])]):_vm._e(),_c('img',{staticClass:"left-sidebar__item-link-icon",attrs:{"src":_vm.NotificationsIcon,"alt":""}})]):_vm._e(),(_vm.isMobile)?_c('a',{staticClass:"nyx-text-icon nyx-text-icon--large notification-toggle",attrs:{"href":"javascript:void(0)"},on:{"click":_vm.toggleDropdown}},[(_vm.unread.hasUnread)?_c('div',{staticClass:"notification-toggle__unread"},[_c('span',[_vm._v(_vm._s(_vm.unread.count))])]):_vm._e(),_c('i',{staticClass:"bi bi-bell"})]):_vm._e(),_c('div',{staticClass:"nyx-notification__menu",class:{
      'show-menu': _vm.showDropdown,
      'hide-menu': !_vm.showDropdown,
      'is--mobile': _vm.isMobile,
    },style:(!_vm.isMobile && `top:${_vm.top}px`)},[(_vm.isLoading)?_c('div',{staticClass:"d-flex justify-content-center py-4"},[_c('InlineLoader')],1):_vm._e(),(!_vm.isLoading && _vm.notifications.length == 0)?_c('div',{staticClass:"d-flex justify-content-center py-4 text-center text__body--smaller text-is--dark text-capitalize"},[_vm._v(" "+_vm._s(_vm.$t("message.generic.no"))+" "+_vm._s(_vm.$t("message.generic.notifications"))+" ")]):_vm._e(),(_vm.notifications.length > 0)?_c('ul',{staticClass:"notification-list"},_vm._l((_vm.sortedNotifications),function(notifications,date){return _c('li',{key:date,staticClass:"mb-4"},[_c('h3',{staticClass:"notification-list__title text-is--dark text-is--light text__body--smaller mb-2"},[_vm._v(" "+_vm._s(date)+" ")]),_c('ul',{staticClass:"notification-list"},_vm._l((notifications),function(notification){return _c('li',{key:notification.id,staticClass:"notification-list__item text-is--dark text-is--light text__body--smaller d-flex align-items-center mb-2 cursor--pointer flex-wrap",class:{
              'opacity-5': notification.isRead,
            }},[_c('span',{staticClass:"opacity-5"},[_vm._v(_vm._s(_vm._f("formatTimeOnly")(notification.createdAt))+" - ")]),_c('span',[_vm._v(_vm._s(notification.message))])])}),0)])}),0):_vm._e()])])
}
var staticRenderFns = []

export { render, staticRenderFns }