<template>
  <div
    class="nyx-search-box"
    :class="{
      'my-4': !clearMargins,
    }"
  >
    <form
      class="nx-input-group nx-input-group--small"
      autocomplete="off"
      @submit.prevent
    >
      <input type="hidden" name="email" class="d-none" />
      <input
        type="text"
        :placeholder="placeholder"
        :value="searchText"
        @input="triggerSearch"
        autocomplete="off"
        name="search"
      />
      <div class="nx-input-group__icon text__body--small text-is--blend-grey">
        <i class="bi bi-search"></i>
      </div>
    </form>
  </div>
</template>

<script>
export default {
  name: "GenericSearchbox",
  props: {
    placeholder: {
      type: String,
      default: "Search...",
    },
    searchRef: {
      type: String,
      default: "",
    },
    clearMargins: Boolean,
  },
  data() {
    return {
      searchText: "",
    };
  },
  mounted() {
    this.clearSearch();
  },
  methods: {
    triggerSearch(event) {
      this.searchText = event.target.value;
      this.$emit("input", this.searchText);
    },
    clearSearch() {
      this.searchText = "";
    },
  },
  watch: {
    searchRef(newVal, oldVal) {
      if (newVal.length === 0) {
        this.clearSearch();
      }
    },
  },
};
</script>

<style scoped></style>
