import { v4 as uuidv4 } from "uuid";
export function generateRandomId(length) {
  return uuidv4();
}
export function generateRecentDate() {
  const currentDate = new Date();
  const randomDays = Math.floor(Math.random() * 365) + 1;
  const randomDate = new Date(
    currentDate.getTime() - randomDays * 24 * 60 * 60 * 1000
  );
  return randomDate.toISOString();
}
export function shortenStringAtCenter(str, maxLength = 24, ellipsis = "...") {
  if (str.length <= maxLength) {
    return str;
  }
  const ellipsisLength = ellipsis.length;
  const centerIndex = Math.floor((maxLength - ellipsisLength) / 2);
  const leftHalf = str.slice(0, centerIndex);
  const rightHalf = str.slice(str.length - centerIndex);
  return leftHalf + ellipsis + rightHalf;
}
export function extractCoinPair(coinPair) {
  return coinPair.replace("/", "");
}
export function extractCoinName(coinPair) {
  return coinPair.replace("/USDT", "");
}
export function clearString(str) {
  return str.replace(/[^\w\s]/gi, "").replace(/\s/g, "");
}
export function getRandomImage() {
  return `https://source.unsplash.com/featured/250x250`;
}
async function getBinanceUrl() {
  let response = await fetch("https://ip2c.org/s", { method: "GET" });
  let data = await response.text();
  let countryCode = data.split("|")[1];
  let binanceUrl = countryCode === "US" ? "binance.us" : "binance.com";
  return binanceUrl;
}
export function getBlockchainLink(network, address) {
  let explorerLink = "";
  const networkName = network?.trim()?.toLowerCase();
  switch (networkName) {
    case "bitcoin":
      explorerLink = `https://www.blockchain.com/btc/address/${address}`;
      break;
    case "ethereum":
      explorerLink = `https://etherscan.io/address/${address}`;
      break;
    case "litecoin":
      explorerLink = `https://explorer.litecoin.net/address/${address}`;
      break;
    case "dogecoin":
      explorerLink = `https://dogechain.info/address/${address}`;
      break;
    case "ripple":
      explorerLink = `https://xrpscan.com/account/${address}`;
      break;
    case "cardano":
      explorerLink = `https://cardanoscan.io/address/${address}`;
      break;
    default:
      explorerLink = `https://generic-explorer.com/address/${address}`;
  }

  return explorerLink;
}

export const replaceSymbol = (symbol) => {
  switch (symbol?.toUpperCase()) {
    case "BSC":
      return symbol?.replace("BSC", "BNB");
      break;
    case "WBTC":
      return symbol?.replace("WBTC", "BTC");
      break;
    case "BBTC":
      return symbol?.replace("BBTC", "BTC");
    case "BBNB":
      return symbol?.replace("BBNB", "BNB");
      break;
    case "WETH":
      return symbol?.replace("WETH", "ETH");
      break;

    default:
      return symbol;
      break;
  }
};
export const filterByTimeframe = (dataset, timeframe) => {
  const now = new Date();
  const timeframeMap = {
    "1D": now.setDate(now.getDate() - 1),
    "1W": now.setDate(now.getDate() - 7),
    "1M": now.setDate(now.getDate() - 30),
    "3M": now.setDate(now.getDate() - 90),
    "6M": now.setDate(now.getDate() - 180),
    "1Y": now.setDate(now.getDate() - 365),
    ATH: now.setDate(now.getDate() - 100000),
  };
  return timeframeMap;
};
export const replaceInterval = (interval) => {
  let days = 1;

  switch (interval) {
    case "1D":
      days = 1;
      break;
    case "1W":
      days = 7;
      break;

    case "1M":
      days = 30;
      break;

    case "3M":
      days = 90;
      break;

    case "6M":
      days = 180;
      break;

    case "1Y":
      days = 365;
      break;

    case "ATH":
      days = 365;
      break;

    default:
      break;
  }
  return days;
};
