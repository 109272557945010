<template>
  <div id="app">
    <transition name="fade" mode="out-in">
      <router-view />
    </transition>
    <LanguageChange />
    <LocationPopup ref="location_popup" />
  </div>
</template>
<script>
import LanguageChange from "./components/Global/LanguageChange.vue";
import GoogleTranslate from "./components/Global/GoogleTranslate.vue";
import LocationPopup from "./components/Global/LocationPopup.vue";
import { API_BASE_PLAIN } from "./config";
import axios from "axios";
export default {
  name: "App",
  components: {
    LanguageChange,
    GoogleTranslate,
    LocationPopup,
  },
  metaInfo: {
    title: "NYX Exchange",
    titleTemplate: "%s - APP",
    htmlAttrs: {
      lang: "en",
      amp: true,
    },
  },
  mounted() {
    if (this.AppTheme == "dark_theme") {
      document.body.classList.add("dark_theme");
    } else {
      document.body.classList.add("light_theme");
    }
    this.getLogin();
  },
  methods: {
    showLocationPopup() {
      this.$refs.location_popup.openPopup();
    },
    async getLogin() {
      try {
        await axios.get(`${API_BASE_PLAIN}/allowCountries`);
      } catch (error) {
        if (
          error?.response &&
          error?.response?.data &&
          !error?.response?.data?.allowed
        ) {
          this.showLocationPopup();
        } else {
          console.log("Go right in!");
        }
      }
    },
  },
};
</script>
<style>
body {
  top: 0 !important;
}
.VIpgJd-ZVi9od-aZ2wEe-OiiCO-ti6hGc,
.VIpgJd-ZVi9od-aZ2wEe,
.skiptranslate {
  display: none !important;
}
</style>
