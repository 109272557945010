import axiosInstance from "@/http";
const state = {
  user: null,
  token: null,
  isAuthenticated: false,
};

const getters = {
  user: (state) => state.user,
  isAuthenticated: (state) => state.isAuthenticated,
  token: (state) => state.token,
};

const mutations = {
  setUser(state, user) {
    state.user = user;
  },
  setToken(state, token) {
    state.token = token;
  },
  setAuthenticated(state, isAuthenticated) {
    state.isAuthenticated = isAuthenticated;
  },
  updateUserInfo(state, user) {
    state.user = user;
  },
};

const actions = {
  // login
  async login({ commit }, payload) {
    try {
      const response = await axiosInstance.post(`/auth/login`, payload);
      commit("setUser", response.data);
      commit("setAuthenticated", true);
      commit("setToken", response.data.token);
      return response;
    } catch (error) {
      throw error;
    }
  },
  async logout({ commit }) {
    try {
      commit("setAuthenticated", false);
      setTimeout(() => {
        commit("setUser", null);
        commit("setToken", null);
      }, 1000);
    } catch (error) {
      throw error;
    }
  },
  // Registration
  async register({ commit }, payload) {
    try {
      const response = await axiosInstance.post(`/auth/register`, payload);
      commit("setUser", response.data.user);
      commit("setAuthenticated", true);
      commit("setToken", response.data.token);
      return response;
    } catch (error) {
      throw error;
    }
  },
  async sendEmailVerification({ commit }, payload) {
    try {
      const response = await axiosInstance.post(
        `/auth/verify-email/send-code`,
        payload
      );
      return response;
    } catch (error) {
      throw error;
    }
  },
  async verifyEmail({ commit }, payload) {
    try {
      const response = await axiosInstance.post(`/auth/verify-email`, payload);
      return response;
    } catch (error) {
      throw error;
    }
  },
  async sendPhoneVerification({ commit }, payload) {
    try {
      const response = await axiosInstance.post(`/auth/phone`, payload);
      return response;
    } catch (error) {
      throw error;
    }
  },
  async verifyPhone({ commit }, payload) {
    try {
      const response = await axiosInstance.post(`/auth/phone/verify`, payload);
      return response;
    } catch (error) {
      throw error;
    }
  },
  // Password Reset
  async requestForgotPassword({ commit }, payload) {
    try {
      const response = await axiosInstance.post(
        `/auth/forgot-passord/request-code`,
        payload
      );
      return response;
    } catch (error) {
      throw error;
    }
  },
  async verifyForgotPassword({ commit }, payload) {
    try {
      const response = await axiosInstance.post(
        `/auth/forgot-passord/verify-code`,
        payload
      );
      return response;
    } catch (error) {
      throw error;
    }
  },
  async changePassword({ commit }, payload) {
    try {
      const response = await axiosInstance.post(
        `/auth/forgot-passord/`,
        payload
      );
      return response;
    } catch (error) {
      throw error;
    }
  },
  // Get User Info
  async updateUserInfo({ commit, state }) {
    try {
      const response = await axiosInstance.get(`/user/${state.user._id}`);
      commit("updateUserInfo", response.data);
    } catch (error) {
      throw error;
    }
  },

  async verifyToken({ commit, state }, token) {
    try {
      const response = await axiosInstance.post(`/auth/2fa/verify-token`, {
        token,
      });

      return response.data;
    } catch (error) {
      throw error;
    }
  },
  async verifyPin({ commit, state }, pin) {
    try {
      const response = await axiosInstance.post(`/user/pin/verify`, {
        pin,
      });
      return response.data;
    } catch (error) {
      throw error;
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
