import Vue from "vue";
import * as AppAssets from "@/assets";
import { mapGetters, mapActions } from "vuex";
import dayjs from "dayjs";
import LocalizedFormat from "dayjs/plugin/localizedFormat";
var isToday = require("dayjs/plugin/isToday");
var isYesterday = require("dayjs/plugin/isYesterday");
dayjs.extend(isYesterday);
dayjs.extend(isToday);
dayjs.extend(LocalizedFormat);
import { EventBus } from "@/helpers/eventBus";

Vue.mixin({
  data() {
    return {
      AppAssets: AppAssets,
    };
  },
  computed: {
    ...mapGetters({
      AppTheme: "theme/theme",
      AppUser: "auth/user",
    }),
  },
  methods: {
    formatCurrency(amount) {
      amount = Number(amount);
      if (isNaN(amount)) {
        return `$0.00`;
      }
      return amount.toLocaleString("en-US", {
        style: "currency",
        currency: "USD",
      });
    },
    async getBinanceUrl() {
      try {
        const response = await fetch("https://ip2c.org/s", { method: "GET" });
        const countryCode = await response.text();
        const isUs = countryCode.includes("US");
        console.log("Your country is", countryCode);
        return !isUs ? "binance.com" : "binance.us";
      } catch (error) {
        console.error(error);
      }
    },
    async initOrder(amount, symbol) {
      try {
        const { data } = await this.$store.dispatch(
          "exchange/initMarketOrder",
          {
            amount,
            symbol,
          }
        );
        console.log(data);
        EventBus.$emit("openOnramper", data);
      } catch (error) {
        console.log(error);
        if (error?.response && error?.response.data) {
          const message =
            error?.response?.data?.message ||
            "An error occurred. Please try again";

          this.$toast.error(message);
        } else {
          this.$toast.error("An error occurred. Please try again");
        }
      }
    },
  },
  filters: {
    formatTimeOnly(date) {
      return dayjs(date).format("LT");
    },
    formatDateOnly(date, format = "MMMM DD, YYYY") {
      return dayjs(date).format(format);
    },
  },
});
