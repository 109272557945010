<template>
  <div
    class="nyx-modal"
    :class="{
      'nyx-modal--open': modalIsOpen,
      'nyx-modal--transparent': isTransparent,
    }"
  >
    <div
      class="nyx-modal__content"
      :class="{
        'nyx-modal__content--large': isLarge,
      }"
    >
      <div class="d-flex align-items-center justify-content-end">
        <a
          href="javascript:void(0)"
          @click="closeModal"
          class="nyx-text-icon nyx-text-icon--large"
          v-if="hasClose"
        >
          <i class="bi bi-x-square"></i>
        </a>
      </div>
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "ModalWrapper",
  props: {
    isLarge: { type: Boolean, default: false },
    hasClose: {
      type: Boolean,
      default: true,
    },
    isTransparent: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      modalIsOpen: false,
    };
  },
  methods: {
    openModal() {
      this.modalIsOpen = true;
      document.body.style.overflow = "hidden";
    },
    closeModal() {
      if (!this.modalIsOpen) return;
      this.modalIsOpen = false;
      document.body.style.overflow = "auto";
      this.$emit("modalClosed");
    },
  },
};
</script>
