<template>
  <div ref="notificationList">
    <button
      class="left-sidebar__item-action notification-toggle"
      ref="notificationButton"
      @click="toggleDropdown"
      v-if="!isMobile"
    >
      <div class="notification-toggle__unread" v-if="unread.hasUnread">
        <span>{{ unread.count }}</span>
      </div>
      <img
        :src="NotificationsIcon"
        class="left-sidebar__item-link-icon"
        alt=""
      />
    </button>
    <a
      href="javascript:void(0)"
      class="nyx-text-icon nyx-text-icon--large notification-toggle"
      v-if="isMobile"
      @click="toggleDropdown"
    >
      <div class="notification-toggle__unread" v-if="unread.hasUnread">
        <span>{{ unread.count }}</span>
      </div>
      <i class="bi bi-bell"></i>
    </a>
    <div
      class="nyx-notification__menu"
      :class="{
        'show-menu': showDropdown,
        'hide-menu': !showDropdown,
        'is--mobile': isMobile,
      }"
      :style="!isMobile && `top:${top}px`"
    >
      <div class="d-flex justify-content-center py-4" v-if="isLoading">
        <InlineLoader />
      </div>
      <div
        class="d-flex justify-content-center py-4 text-center text__body--smaller text-is--dark text-capitalize"
        v-if="!isLoading && notifications.length == 0"
      >
        {{ $t("message.generic.no") }} {{ $t("message.generic.notifications") }}
      </div>
      <ul class="notification-list" v-if="notifications.length > 0">
        <li
          v-for="(notifications, date) in sortedNotifications"
          :key="date"
          class="mb-4"
        >
          <h3
            class="notification-list__title text-is--dark text-is--light text__body--smaller mb-2"
          >
            {{ date }}
          </h3>
          <ul class="notification-list">
            <li
              v-for="notification in notifications"
              :key="notification.id"
              class="notification-list__item text-is--dark text-is--light text__body--smaller d-flex align-items-center mb-2 cursor--pointer flex-wrap"
              :class="{
                'opacity-5': notification.isRead,
              }"
            >
              <span class="opacity-5"
                >{{ notification.createdAt | formatTimeOnly }} -
              </span>
              <span>{{ notification.message }}</span>
              <!-- <span>{{ parseFloat(notification.amount).toFixed(3) }}</span> -->
            </li>
          </ul>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import dayjs from "dayjs";
import NotificationsIcon from "@/assets/menuIcons/Notifications.svg";
import axiosInstance from "@/http";

export default {
  name: "Notifications",
  props: ["top", "isMobile"],
  data() {
    return {
      NotificationsIcon,
      showDropdown: false,
      isLoading: false,
      notifications: [],
    };
  },
  computed: {
    unread() {
      const unreadNotifications = this.notifications.filter(
        (notification) => !notification.isRead
      );
      return {
        hasUnread: unreadNotifications.length > 0,
        count: unreadNotifications.length,
      };
    },
    sortedNotifications() {
      const today = dayjs().startOf("day");
      return this.notifications
        .sort((a, b) => dayjs(b.createdAt).diff(dayjs(a.createdAt)))
        .reduce((accumulator, notification) => {
          const notificationDate = dayjs(notification.createdAt).startOf("day");
          const key = notificationDate.isSame(today)
            ? "Today"
            : notificationDate.format("MMMM DD, YYYY");
          if (!accumulator[key]) {
            accumulator[key] = [];
          }
          accumulator[key].push(notification);
          return accumulator;
        }, {});
    },
  },
  mounted() {
    document.addEventListener("click", this.closeMenuIfClickedOutside);
    this.getAllNotifications();
  },
  beforeDestroy() {
    document.removeEventListener("click", this.closeMenuIfClickedOutside);
  },
  methods: {
    async getAllNotifications() {
      this.isLoading = true;
      try {
        const { data } = await axiosInstance.get("/notification");
        this.notifications = data;
      } catch (error) {
        console.log(error);
      } finally {
        this.isLoading = false;
      }
    },
    async viewNotifcation(id) {
      this.isLoading = true;
      try {
        const { data } = await axiosInstance.get(`/notification/${id}`);
        console.log(data);
      } catch (error) {
        console.log(error);
      } finally {
        this.isLoading = false;
      }
    },
    toggleDropdown() {
      this.showDropdown = !this.showDropdown;
    },
    hideDropdownMenu() {
      this.showDropdown = false;
    },
    closeMenuIfClickedOutside(event) {
      const menuListEl = this.$refs.notificationList;
      const isOutsideClick = !menuListEl.contains(event.target);
      const isMenuShown = this.showDropdown;

      if (isOutsideClick && isMenuShown) {
        this.hideDropdownMenu();
      }
    },
  },
};
</script>

<style scoped>
.nyx-notification {
  position: relative;
}

.nyx-notification__menu {
  position: fixed;
  left: 110px;
  min-height: 300px;
  max-height: 320px;
  width: 250px;
  background: var(--baseWhite);
  box-shadow: var(--baseShadow2);
  border-radius: var(--smallRadius);
  padding: 1rem;
  opacity: 0;
  visibility: hidden;
  z-index: 200;
  overflow-y: auto;
}

.nyx-notification__menu::-webkit-scrollbar {
  width: 2px;
}

.show-menu {
  animation: fadeIn 0.5s forwards;
  opacity: 1;
  visibility: visible;
}

.hide-menu {
  animation: fadeOut 0.5s forwards;
}

.notification-list {
  list-style: none;
  padding: 0;
}

.notification-list__item {
  padding: 0.3rem;
  transition: 0.5s ease-in-out;
  border-radius: 0.3rem;
}

.notification-list__item:hover {
  background: var(--blendWhite2);
}

.notification-toggle {
  position: relative;
}

.notification-toggle .notification-toggle__unread {
  position: absolute;
  top: 0;
  right: 0;
  height: 13px;
  width: 13px;
  background: var(--dangerColor);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  font-size: 8px;
  color: #fff;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    visibility: hidden;
  }

  to {
    opacity: 1;
    visibility: visible;
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
    visibility: visible;
  }

  to {
    opacity: 0;
    visibility: hidden;
  }
}
</style>
