const state = {
  coinPairs: [
    {
      name: "BTC/USDT",
    },
    {
      name: "ETH/USDT",
    },
    {
      name: "LTC/USDT",
    },
    {
      name: "ADX/USDT",
    },
    {
      name: "BUSD/USDT",
    },
    {
      name: "XRP/USDT",
    },
    {
      name: "SOL/USDT",
    },
    {
      name: "BNB/USDT",
    },
  ],
  activeCoinPair: {
    name: "BTC/USDT",
  },
};

const mutations = {
  setActiveCoinPair(state, coinPair) {
    state.activeCoinPair = coinPair;
  },
  setCoinPairs(state, pairs) {
    state.coinPairs = pairs;
  },
};

const getters = {
  currentActiveCoinPair(state) {
    return state.activeCoinPair;
  },
  allCoinPairs: (state) => {
    return state.coinPairs;
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  getters,
};
