<template>
 <div class="container-fluid">
  <div class="col-xxl-4 col-lg-6 col-sm-8 col-12 mx-auto my-5">
   <div class="auth-card">
    <slot></slot>
   </div>
  </div>
 </div>
</template>

<script>
export default {
 name: "AuthCard"
}
</script>

<style></style>