<template>
 <div class="px-3 px-md-5 nyx-list-item py-1" :class="{
  'nyx-list-item--active': isActive
 }">
  <div class="d-flex align-items-center justify-content-between my-3">
   <div class="d-flex align-items-center pe-2">
    <div class="me-3">
     <div class="nyx-wallet-icon">
      <img :src="wallet.logo" v-img-loaded="require('@/assets/icons/placeholder/broken_coin_icon.svg')" alt="">
     </div>
    </div>
    <div>
     <div class="text__body--small text-is--dark">{{ wallet.name }}</div>
     <div class="text__body--smaller text-is--light text-is--dark text-uppercase">{{ wallet.symbol }}</div>
    </div>
   </div>
   <div class="text__body--small text-is--dark">{{ wallet.balance }}</div>
  </div>
 </div>
</template>

<script>
export default {
 name: "WalletListItem",
 props: {
  wallet: {
   type: Object,
   required: true
  },
  isActive: {
   type: Boolean,
   required: true
  }
 },
}
</script>
