<template>
 <span class="text-danger small">{{ message }}</span>
</template>

<script>
export default {
 name: "InlineError",
 props: {
  message: String
 }
}
</script>

<style></style>