<template>
  <div>
    <div
      class="d-flex align-items-center container-fluid py-4 px-lg-5 px-3 app-top-header"
    >
      <div class="mobile-view-actions me-2 mt-2">
        <a
          href="javascript:void(0)"
          @click="showMobileMenu"
          class="nyx-text-icon nyx-text-icon--large"
        >
          <i class="bi bi-grid"></i>
        </a>
      </div>
      <div class="d-flex align-items-center">
        <img
          :src="
            AppTheme === 'dark_theme'
              ? AppAssets.BRAND.FullWhiteLogo
              : AppAssets.BRAND.FullDarkLogo
          "
          class="brand-logo"
          alt=""
        />
        <div class="mx-4 app-top-header__divider d-none d-lg-block"></div>
        <div
          class="text__body text-is--semibold text-is--dark d-none d-lg-block"
        >
          {{ currentRouteName }}
        </div>
      </div>
      <div class="mobile-view-actions me-2 mt-2">
        <Notifications :isMobile="true" />
      </div>
    </div>
    <MobileMenu ref="mobile_menu" />
  </div>
</template>

<script>
import MobileMenu from "./MobileMenu";
import Notifications from "@/components/Dashboard/Notifications/Notifications";
export default {
  name: "TopHeader",
  components: {
    MobileMenu,
    Notifications,
  },
  props: {
    currentRouteName: String,
  },
  methods: {
    showMobileMenu() {
      this.$refs.mobile_menu.openMobileMenu();
    },
  },
};
</script>

<style scoped>
.app-top-header .app-top-header__divider {
  width: 2px;
  height: 30px;
  background: var(--fixedGrey);
}
</style>
