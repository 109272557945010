<template>
 <div class="px-3 nyx-list-item py-1" :class="{
  'nyx-list-item--active': isActive
 }">
  <div class="d-flex align-items-center justify-content-between my-2">
   <div class="d-flex align-items-center">
    <div>
     <div class="text__body--small text-is--dark">{{ option.value }}</div>
    </div>
   </div>
  </div>
 </div>
</template>

<script>
export default {
 name: "TextDropdownItem",
 props: {
  option: {
   type: Object,
   required: true
  },
  isActive: {
   type: Boolean,
   required: true
  }
 },
}
</script>
