<template>
 <div class="nyx-check-component">
  <div class="nyx-check-component__checked d-flex align-items-center justify-content-center" :class="{
   'isChecked': isChecked
  }">
   <i class="bi bi-check check-icon"></i>
  </div>
 </div>
</template>

<script>
export default {
 name: "Checkbox",
 props: {
  isChecked: {
   type: Boolean,
   default: false
  }
 }
}
</script>

<style scoped>
.nyx-check-component {
 height: 18px;
 width: 18px;
 border-radius: 5px;
 border: 1px solid var(--primaryPurple);
 overflow: hidden;
 cursor: pointer;
}

.nyx-check-component .nyx-check-component__checked {
 height: 100%;
 width: 100%;
 background: var(--primaryPurple);
 color: #FFF;
 font-size: 1rem;
 transition: 0.5s ease-in-out;
 opacity: 0;
}

.nyx-check-component .nyx-check-component__checked.isChecked {
 opacity: 1;
 transition: 0.5s ease-in-out;
}

.nyx-check-component .nyx-check-component__checked .check-icon {
 display: block;
 margin-top: 4px;
}
</style>