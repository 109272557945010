import axiosInstance from "@/http";
const state = {
  coldWallets: [],
  activeColdWallet: null,
  portfolioValue: 0,
};
const mutations = {
  setActiveWallet(state, wallet) {
    state.activeColdWallet = wallet;
  },
  setAllWallets(state, wallets) {
    state.coldWallets = wallets;
  },
  setPortfolioValue(state, value) {
    state.portfolioValue = value;
  },
};
const getters = {
  allColdWallets: (state) => state.coldWallets,
  currentActiveColdWallet: (state) => state.activeColdWallet,
  coldWalletPortfolioValue: (state) => state.portfolioValue,
};
const actions = {
  async getList({ commit }, payload) {
    try {
      const response = await axiosInstance.get(`/coldwallet`);
      const wallets = response.data.wallets;
      commit("setAllWallets", wallets);
      commit("setActiveWallet", wallets[0]);
      commit("setPortfolioValue", response.data.totalBalance);
      return response;
    } catch (error) {
      throw error;
    }
  },
  async getWalletTransactions({ commit }, payload) {
    try {
      const response = await axiosInstance.get(`/coldwallet/${payload.id}`);
      return response;
    } catch (error) {
      throw error;
    }
  },
  async create({ commit }, payload) {
    try {
      const response = await axiosInstance.post(`/coldwallet`, payload);
      return response;
    } catch (error) {
      throw error;
    }
  },
  async withdraw({ commit }, payload) {
    try {
      const response = await axiosInstance.post(`/coldwallet`, payload);
      return response;
    } catch (error) {
      throw error;
    }
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
};
