var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{},[_c('div',{staticClass:"d-flex align-items-center justify-content-between cursor--pointer",on:{"click":_vm.openChangeModal}},[(_vm.selectedWallet)?_c('div',{staticClass:"d-flex align-items-center me-4"},[_c('div',{staticClass:"me-3"},[_c('div',{staticClass:"nyx-wallet-icon nyx-wallet-icon--medium"},[_c('img',{directives:[{name:"img-loaded",rawName:"v-img-loaded",value:(
              require('@/assets/icons/placeholder/broken_coin_icon.svg')
            ),expression:"\n              require('@/assets/icons/placeholder/broken_coin_icon.svg')\n            "}],attrs:{"src":_vm.selectedWallet.logo,"alt":""}})])]),_c('div',[_c('div',{staticClass:"text__body--small text-is--dark text-is--semibold"},[_vm._v(" "+_vm._s(_vm.selectedWallet.name)+" ")]),_c('div',{staticClass:"text__body--smaller text-is--light text-is--dark"},[_vm._v(" "+_vm._s(_vm.selectedWallet.symbol)+" ")])])]):_c('div',{staticClass:"d-flex align-items-center me-4"},[_vm._m(0),_c('div',{staticClass:"text__body--small text-is--dark text-is--regular"},[_vm._v(" "+_vm._s(_vm.$t(`message.generic.select`))+" "+_vm._s(_vm.$t(`message.generic.wallets`))+" ")])]),_vm._m(1)]),_c('modal-wrapper',{ref:"wallet_change"},[_c('div',{staticClass:"nyx-list-area"},[_c('GenericSearchbox',{attrs:{"placeholder":`${_vm.$t(`message.generic.search`)} ${_vm.$t(
          `message.generic.wallets`
        )}`},model:{value:(_vm.searchTerm),callback:function ($$v) {_vm.searchTerm=$$v},expression:"searchTerm"}}),_vm._l((_vm.cryptoWallets),function(wallet){return _c('div',{key:wallet.symbol,on:{"click":function($event){return _vm.chooseWallet(wallet)}}},[_c('WalletListItem',{attrs:{"wallet":wallet,"isActive":_vm.selectedWallet ? _vm.selectedWallet.symbol == wallet.symbol : false}})],1)})],2)])],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"me-3"},[_c('div',{staticClass:"nyx-wallet-icon nyx-wallet-icon--medium"},[_c('img',{attrs:{"src":require("@/assets/icons/WalletIconPlaceholder.svg"),"alt":""}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('img',{staticClass:"nyx-icon nyx-icon--smal",attrs:{"src":require("@/assets/icons/SearchSortIcon.svg"),"alt":""}})])
}]

export { render, staticRenderFns }