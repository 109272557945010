<template>
  <AuthCard>
    <div class="mb-2">
      <h1 class="text__title text-is--primary text-is--regular">
        {{ $t("message.enterPhoneNumber.title") }}
      </h1>
      <p class="text__body--small text-is--dark text-is--light">
        {{ $t("message.enterPhoneNumber.description") }}
      </p>
    </div>
    <form @submit.prevent="supplyPhoneNumber">
      <div class="form-group mb-4">
        <div class="nx-input-group">
          <div class="me-2">
            <country-code
              @onSelect="onSelect"
              :enabledCountryCode="true"
            ></country-code>
          </div>
          <input
            type="text"
            :placeholder="$t('message.enterPhoneNumber.placeholder')"
            v-model.trim="$v.phone.number.$model"
            inputmode="numeric"
          />
        </div>
      </div>
      <div
        class="mt-5 d-block d-md-flex align-items-center justify-content-end"
      >
        <button
          class="nx-button nx-button--rounded nx-button--primary"
          type="submit"
          :disabled="isLoading || $v.$invalid"
        >
          <span>{{ $t("message.enterPhoneNumber.nextButton") }}</span>
          <img
            :src="AppAssets.ICONS.BoxArrowRight"
            class="nx-button__icon"
            alt=""
            v-if="!isLoading"
          />
          <InlineLoader v-if="isLoading" />
        </button>
      </div>
    </form>
  </AuthCard>
</template>

<script>
import { AuthCard } from "@/components";
import { required, email, minLength } from "vuelidate/lib/validators";
import { clearString } from "@/helpers";
export default {
  name: "PhoneNumber",
  components: { AuthCard },
  data() {
    return {
      fields: null,
      isLoading: false,
      phone: {
        countryCode: "",
        number: "",
      },
    };
  },
  validations: {
    phone: {
      number: {
        required,
        minLength: minLength(6),
      },
    },
  },
  methods: {
    setFields(fields) {
      this.fields = {
        ...fields,
      };
      console.log(this.fields);
    },
    async supplyPhoneNumber() {
      this.isLoading = true;
      try {
        const data = {
          ...this.fields,
          phone: this.phone,
        };
        await this.$store.dispatch("auth/sendPhoneVerification", {
          phone: {
            countryCode: this.phone.countryCode,
            number: clearString(this.phone.number),
          },
        });
        await this.$store.dispatch("auth/register", {
          password: this.fields.password,
          emailOTP: this.fields.emailOTP,
          // phoneOTP: this.phoneOTP,
          shareData: this.fields.shareData,
          recieveUpdates: this.fields.recieveUpdates,
        });
        this.$router.push("/dashboard");
        // this.$emit("phoneNumberSupplied", data);
      } catch (error) {
        console.log(error);
        if (error.response && error.response.data) {
          this.$toast.error(error.response.data[0].message);
        } else {
          this.$toast.error("An error occurred. Please try again");
        }
      } finally {
        this.isLoading = false;
      }
    },
    onSelect({ name, iso2, dialCode }) {
      console.log(name, iso2, dialCode);
      this.phone.countryCode = `+${dialCode}`;
    },
  },
};
</script>

<style></style>
