import axiosInstance from "@/http";
const state = {};
const mutations = {};
const getters = {};
const actions = {
  async getAllLoans({ commit }, payload) {
    try {
      const response = await axiosInstance.get(`/p2p`);
      return response;
    } catch (error) {
      throw error;
    }
  },
  async getAllMyLoanRequests({ commit }) {
    try {
      const response = await axiosInstance.get(`/p2p/get-my-loan-requests`);
      return response;
    } catch (error) {
      throw error;
    }
  },
  async getAllMyAcceptedRequests({ commit }) {
    try {
      const response = await axiosInstance.get(`/p2p/get-my-accepted-loans`);
      return response;
    } catch (error) {
      throw error;
    }
  },
  async getLendsIAccepted({ commit }) {
    try {
      const response = await axiosInstance.get(`/p2p/get-my-accepted-lends`);
      return response;
    } catch (error) {
      throw error;
    }
  },
  async getAllMyRepaidLoans({ commit }) {
    try {
      const response = await axiosInstance.get(`/p2p/get-my-repaid-loans`);
      return response;
    } catch (error) {
      throw error;
    }
  },
  async getAllMyRepaidLoanRequests({ commit }) {
    try {
      const response = await axiosInstance.get(
        `/p2p/get-my-repaid-loan-requests`
      );
      return response;
    } catch (error) {
      throw error;
    }
  },
  async createNewLend({ commit }, payload) {
    try {
      const response = await axiosInstance.post(`/p2p`, payload);
      return response;
    } catch (error) {
      throw error;
    }
  },
  async acceptLend({ commit }, payload) {
    try {
      const response = await axiosInstance.post(`/p2p/lend/${payload.id}`);
      return response;
    } catch (error) {
      throw error;
    }
  },
  async repayLend({ commit }, payload) {
    try {
      const response = await axiosInstance.post(`/p2p/repay/${payload.id}`);
      return response;
    } catch (error) {
      throw error;
    }
  },
  // Staking
  async getPlans({ commit }) {
    try {
      const response = await axiosInstance.get(`/staking/plan`);
      return response;
    } catch (error) {
      throw error;
    }
  },
  async getStakes({ commit }) {
    try {
      const response = await axiosInstance.get(`/staking/stake`);
      return response;
    } catch (error) {
      throw error;
    }
  },
  async createStake({ commit }, payload) {
    try {
      const response = await axiosInstance.post(`/staking/stake`, payload);
      return response;
    } catch (error) {
      throw error;
    }
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
};
