import axiosInstance from "@/http";
const state = {};
const mutations = {};
const getters = {};
const actions = {
  async createAlert({ commit }, payload) {
    try {
      const response = await axiosInstance.post(`/alert`, payload);
      return response;
    } catch (error) {
      throw error;
    }
  },
  async createMarketBuyOrder({ commit }, payload) {
    try {
      const response = await axiosInstance.post(`/order`, payload);
      return response;
    } catch (error) {
      throw error;
    }
  },
  async initMarketOrder({ commit }, payload) {
    try {
      const response = await axiosInstance.post(`/order/init`, payload);
      return response;
    } catch (error) {
      throw error;
    }
  },
  async createMarketSellOrder({ commit }, payload) {
    try {
      const response = await axiosInstance.post(`/order`, payload);
      return response;
    } catch (error) {
      throw error;
    }
  },
  async createDarkPoolSellOrder({ commit }, payload) {
    try {
      const response = await axiosInstance.post(`/order`, payload);
      return response;
    } catch (error) {
      throw error;
    }
  },
  async createDarkPoolBuyOrder({ commit }, payload) {
    try {
      const response = await axiosInstance.post(`/order`, payload);
      return response;
    } catch (error) {
      throw error;
    }
  },
  async getExchangeRate({ commit }, payload) {
    try {
      const response = await axiosInstance.get(
        `/convert?from=${payload.from}&to=${payload.to}&quantity=${payload.quantity}`
      );
      return response;
    } catch (error) {
      throw error;
    }
  },
  async getRate({ commit }, payload) {
    try {
      const response = await axiosInstance.post(`/order/rate`, payload);
      return response;
    } catch (error) {
      throw error;
    }
  },
  async swapCoins({ commit }, payload) {
    try {
      const response = await axiosInstance.post(`/order`, payload);
      return response;
    } catch (error) {
      throw error;
    }
  },
  // Transactions
  async getOrders({ commit }, payload) {
    try {
      const response = await axiosInstance.get(
        `/order?page=${payload.page}&limit=${payload.limit}&type=${payload.type}`
      );
      return response;
    } catch (error) {
      throw error;
    }
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
};
