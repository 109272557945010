<template>
  <div class="">
    <div
      class="d-flex align-items-center justify-content-between cursor--pointer"
      @click="openChangeModal"
    >
      <div class="d-flex align-items-center me-4" v-if="selectedWallet">
        <div class="me-3">
          <div class="nyx-wallet-icon nyx-wallet-icon--medium">
            <img
              v-img-loaded="
                require('@/assets/icons/placeholder/broken_coin_icon.svg')
              "
              :src="selectedWallet.logo"
              alt=""
            />
          </div>
        </div>
        <div>
          <div class="text__body--small text-is--dark text-is--semibold">
            {{ selectedWallet.name }}
          </div>
          <div class="text__body--smaller text-is--light text-is--dark">
            {{ selectedWallet.symbol }}
          </div>
        </div>
      </div>
      <div class="d-flex align-items-center me-4" v-else>
        <div class="me-3">
          <div class="nyx-wallet-icon nyx-wallet-icon--medium">
            <img src="@/assets/icons/WalletIconPlaceholder.svg" alt="" />
          </div>
        </div>
        <div class="text__body--small text-is--dark text-is--regular">
          {{ $t(`message.generic.select`) }} {{ $t(`message.generic.wallets`) }}
        </div>
      </div>
      <div>
        <img
          src="@/assets/icons/SearchSortIcon.svg"
          class="nyx-icon nyx-icon--smal"
          alt=""
        />
      </div>
    </div>
    <modal-wrapper ref="wallet_change">
      <div class="nyx-list-area">
        <GenericSearchbox
          :placeholder="`${$t(`message.generic.search`)} ${$t(
            `message.generic.wallets`
          )}`"
          v-model="searchTerm"
        />
        <div
          v-for="wallet in cryptoWallets"
          :key="wallet.symbol"
          @click="chooseWallet(wallet)"
        >
          <WalletListItem
            :wallet="wallet"
            :isActive="
              selectedWallet ? selectedWallet.symbol == wallet.symbol : false
            "
          />
        </div>
      </div>
    </modal-wrapper>
  </div>
</template>

<script>
import WalletListItem from "@/components/Dashboard/Wallets/WalletListItem";
export default {
  name: "CryptoSelect",
  components: { WalletListItem },
  props: {
    selected: {
      type: Object,
    },
    exclude: String,
  },
  data() {
    return {
      selectOpen: false,
      coinSelected: false,
      searchTerm: "",
      wallets: [],
      selectedWallet: null,
    };
  },
  mounted() {
    this.getCryptoAssets();
  },
  computed: {
    cryptoWallets() {
      let filtered = [...this.$store.getters["assets/cryptoAssets"]];
      filtered.sort((a, b) => a.name.localeCompare(b.name));
      if (this.exclude) {
        filtered = filtered.filter((wallet) => wallet.name !== this.exclude);
      }
      if (this.searchTerm) {
        filtered = filtered.filter(
          (wallet) =>
            wallet.name.toLowerCase().includes(this.searchTerm.toLowerCase()) ||
            wallet.symbol.toLowerCase().includes(this.searchTerm.toLowerCase())
        );
      }
      if (this.selectedWallet) {
        filtered = filtered.sort((a, b) => {
          if (a.name === this.selectedWallet.name) {
            return -1;
          }
          if (b.name === this.selectedWallet.name) {
            return 1;
          }
          return 0;
        });
      }
      return filtered;
    },
  },
  methods: {
    reset() {
      this.selectedWallet = null;
    },
    async getCryptoAssets() {
      try {
        await this.$store.dispatch("assets/getCryptoAssets");
      } catch (error) {
        console.log(error);
      }
    },
    openChangeModal() {
      this.$refs.wallet_change.openModal();
    },
    closeChangeModal() {
      this.$refs.wallet_change.closeModal();
    },
    chooseWallet(wallet) {
      this.selectedWallet = wallet;
      this.closeChangeModal();
      this.$emit("selected", wallet);
    },
  },
};
</script>

<style></style>
